@import '../../theme.scss';

.root {
  display: flex;
  flex-wrap: wrap;
  width: calc(50% - 2px);
  border-bottom: 0;
  background-color: $color-dark;
  border-radius: 16px;
  padding: 2px 12px 0px 16px;
  margin: 0px 2px 2px 0px;

  &.fullWidth {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex ;
  }

  .select {
    color: $color-light;
    font-family: Alegreya, serif;
  }

  svg {
    color: $color-light;
    margin-right: 8px;
  }

  &.disabled {
    :global(svg) {
      font-size: 18px;
      margin-top: 2px;
      margin-right: 10px;
      color: $color-light;
    }
  }
}

.menu {
  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    margin-left: 0px;
    border-radius: 16px;
    color: $color-light;

    .lineageHeader {
      font-family: Alegreya, serif;
      padding: 8px 16px;
      background-color: $color-off-dark;
      color: $color-light;
    }

    .item {
      font-family: Alegreya, serif;
      padding: 8px 16px;
      min-height: 0px;

      &.disabled {
        color: $color-light;
      }

      &:global(.Mui-selected) {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }
}
