@import '../../theme.scss';

.statgrid-overlay {
  background-color: $color-light;
  width: 75%;
  max-width: 224px;
  padding: 16px;
  border: 0;
  margin: 0px 0 0 0;
  border-radius: 16px;
  color: $color-dark;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  z-index: 24;

  &.body {
    border-top-left-radius: 0px;
  }
  &.infection {
    border-top-right-radius: 0px;
  }
  .grid {
    .stat-element {
      .control-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .stat-info {
        display: flex;
        justify-content: space-around;
        margin-top: 12px;

        .focus-point {
          font-size: 24px;
          font-weight: bolder;
          
        }

        .focus-stat {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 8px;
        }

        
      }

      .reduction-container {
        display: flex;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 0px;
        justify-content: center;
        align-items: center;

        .reduction-view-only {
          font-family: 'Bellefair', serif;
          font-size: 24px;
          font-weight: bolder;
          margin-left: 8px;
          margin-top: -4px;
        }

        .reduction-control {
          display: flex;
          justify-content: center;
          cursor: pointer;
          color: $color-light;
          margin-left: 8px;
          margin-top: -2px;

          padding: 2px 2px;
          font-size: 18px;

          .numeric {
            padding: 2px 8px;
            background-color: $color-off-dark;
          }

          .stat-control {
            width: 24px;
            background-color: $color-off-dark;
            user-select: none;

            &:hover {
              filter: brightness(125%);
            }

            &.disabled {
              color: transparent;
              cursor: default;
              &:hover {
                filter: none;
              }
            }

            &.left {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              border-right: 1px solid $color-midtone;
            }

            &.right {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border-left: 1px solid $color-midtone;
            }
          }
        }
      }
    }
  }
}

.fracture-control {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8px;
  font-size: 16px;

  .fracture-left {
    margin-right: 8px;
  }

  .fracture-right {
    font-size: 24px;
    font-weight: bolder;
  }
}