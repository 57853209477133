@import '../../theme.scss';

.container {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  .loreGroup {
    width: 100%;
    margin-top: 4px;
  }

  .loreGraph {
    width: 100%;
  }
}