@import "../../theme.scss";

.container {
	max-width: calc(100vw - 264px);
	margin: 0 auto;
	margin-top: 64px;
	padding: 0px 8px;
	padding-bottom: 16px;
	overflow-y: auto;

	.eventLinks {
		max-width: 640px;
		margin: 0 auto;
	}

	.charts {
		display: flex;
		flex-wrap: wrap;

		&.withControls {
			width: 100%;
			justify-content: center;
		}

		.sortGroup {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			margin-top: 32px;

			.sorter {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				margin: 8px 0px;
				margin-bottom: 0px;
				z-index: 2;
				max-width: 640px;

				&.tight {
					justify-content: center;
					width: 100%;
				}

				&.secondGroup {
					border-top: 1px solid $color-midtone;
					padding-top: 16px;
					transition: all 300ms ease-in-out;
					z-index: 1;

					&.entering, &.entered {
						opacity: 1;
						margin-bottom: 0px;
					}

					&.exiting, &.exited {
						opacity: 0;
						margin-bottom: -128px;
					}
				}

				.static {
					padding: 4px 16px;
					border-radius: 16px;
					background-color: $color-off-dark;
					border: 1px solid $color-off-dark;
					margin: 0px 4px;
					margin-bottom: 8px;

					&:first-of-type {
						margin-left: 0;
					}

					&:last-of-type {
						margin-right: 0;
					}

					&.selectable {
						background-color: $color-dark;
						border: 1px solid $color-dark;
						cursor: pointer;
						transition: background-color 0.2s, color 0.2s;

						&:hover {
							background-color: $color-light;
							color: $color-dark;
						}

						&.selected {
							cursor: default;
							background-color: $color-light;
							color: $color-dark;
						}
					}
				}
			}
		}

		.vis {
			margin: 0 auto;
		}

		.chart {
			width: 480px;
			max-width: calc(100vw - 224px);
			height: 240px;
			margin: 0 auto;

			&.pie, &.cdf {
				height: 320px;
			}

			&.cdf, &.stackedBar {
				width: 800px;
			}

			&.stackedBar {
				height: 1024px;
				margin: 0 auto;

				&.basic {
					:global(rect[stroke="#8f4a26"]), :global(rect[stroke="#6f4a7f"]), :global(rect[stroke="#546a4c"]), :global(rect[stroke="#007a80"]) {
						stroke-width: 1px;
						animation: blinkingStroke 1.5s infinite;
					}
				}

				&.proficient {
					:global(rect[stroke="#7e3a16"]), :global(rect[stroke="#613674"]), :global(rect[stroke="#374532"]), :global(rect[stroke="#00696e"]) {
						stroke-width: 1px;
						animation: blinkingStroke 1.5s infinite;
					}
				}

				&.master {
					:global(rect[stroke="#722f0b"]), :global(rect[stroke="#46145b"]), :global(rect[stroke="#213819"]), :global(rect[stroke="#00585c"]) {
						stroke-width: 1px;
						animation: blinkingStroke 1.5s infinite;
					}
				}

				&.achievement {
					:global(rect[stroke="#682603"]), :global(rect[stroke="#3c0952"]), :global(rect[stroke="#1a3111"]), :global(rect[stroke="#004a4d"]) {
						stroke-width: 1px;
						animation: blinkingStroke 1.5s infinite;
					}
				}
			}
		}
	}
}

.reverse {
	display: flex;
	width: 100%;

	&:first-of-type {
		margin-top: 4px;
	}

	.playerName {
		flex: 0 0 40%;
		border-right: 1px solid $color-midtone;
		text-align: right;
		padding-right: 8px;
		white-space: nowrap;
		text-overflow: hidden;
		overflow: hidden;
		direction: rtl;
	}

	.characterName {
		flex: 0 0 60%;
		padding-left: 8px;
		white-space: nowrap;
		text-overflow: hidden;
		overflow: hidden;
	}
}

@keyframes blinkingStroke {
	0% { stroke: transparent; }
	50% { stroke: $color-light; }
	100% { stroke: transparent; }
}
@media screen and (max-width: 740px) {
	.container {
		max-width: calc(100vw);

		.charts {
			.chart {
				max-width: calc(100vw - 16px);
			}
		}
	}
}

@media screen and (min-width: 1280px) {
	.container {
		.charts {
			&.withControls {
				justify-content: center;

				.sortGroup {
					margin: 0;
					margin-right: 16px;
					margin-top: 16px;
					align-items: flex-start;
					align-content: flex-start;
					justify-content: center;

					.sorter {
						.static {
							width: 128px;
							text-align: center;
							padding-left: 8px;
							padding-right: 8px;
							margin: 0;
							margin-bottom: 8px;
						}

						&.secondGroup {
							.static {
								&.selectable {
									&.cumulative {
										transition: margin-right 600ms 0ms ease-in-out, margin-left 600ms 0ms ease-in-out, opacity 500ms 250ms ease-in-out;
									}

									&.basic {
										transition: margin-right 500ms 100ms ease-in-out, margin-left 500ms 100ms ease-in-out, opacity 500ms 250ms ease-in-out;
									}

									&.proficient {
										transition: margin-right 400ms 200ms ease-in-out, margin-left 400ms 200ms ease-in-out, opacity 500ms 250ms ease-in-out;
									}

									&.master {
										transition: margin-right 300ms 300ms ease-in-out, margin-left 300ms 300ms ease-in-out, opacity 500ms 250ms ease-in-out;
									}

									&.achievement {
										transition: margin-right 200ms 400ms ease-in-out, margin-left 200ms 400ms ease-in-out, opacity 500ms 250ms ease-in-out;
									}

									&.entering, &.entered {
										opacity: 1;
										margin-left: 0px;
										margin-right: 0px;
									}

									&.exiting, &.exited {
										opacity: 0;
										margin-left: -128px;
										margin-right: 128px;
									}
								}
							}
						}
					}
				}

				.vis {
					margin: 0;
				}

				.chart {
					.stackedBar {
						max-width: 960px;
						;
					}
				}
			}

			.sortGroup {
				flex: 0 0 128px;
			}
		}
	}
}
