@import '../../theme.scss';

.container {
  max-width: 480px;
  margin: 0 auto;
  .row {
    padding: 8px 8px 0px 8px;

    &.refusalOfService, &.suspension {
      background: repeating-linear-gradient(-45deg, $color-combat-1 0, $color-combat-1 10px, $color-combat-3 10px, $color-combat-3 20px);
      border-left: 4px solid $color-combat-1;
    }

    &.warning, &.violation {
      background: repeating-linear-gradient(-45deg, $color-civilized-1 0, $color-civilized-1 10px, $color-civilized-0 10px, $color-civilized-0 20px);
      border-left: 4px solid $color-civilized-4;
    }
    .title {
      padding-bottom: 8px;
      color: $color-light;
    }

    .reason, .expiration {
      background-color: $color-light;
      color: $color-dark;
      margin-left: -8px;
      margin-right: -8px;
      padding: 4px 8px;
    }
  }

  .sectionHeader {
    text-align: center;
    font-size: 20px;
    margin: 16px 0px;
  }

  .sectionHeader {
    cursor: pointer;
    .drop {
      margin-bottom: -6px;
      margin-left: 8px;
    }
  }

  .expired {
    .row {
      &.refusalOfService, &.suspension {
        background: none;
        background-color: $color-combat-1;
      }

      &.warning, &.violation {
        background: none;
        background-color: $color-civilized-4;
      }
    }
  }
}
