@import '../theme.scss';

.container {
  width: calc(100vw - 256px);
  min-width: calc(480px - 16px);
  max-width: 640px;
  margin: 0 auto;
  margin-top: 64px;
  justify-content: flex-start;

  &.multiTenant {
    display: flex;

    .searchResults {
      margin-top: 36px;
      right: 8px;
      left: 8px;
    }
  }

  &.lightweight {
    min-width: calc(480px - 24px);

    &.left {
      justify-content: flex-start;

      .searchControl {
        justify-content: flex-start;

        .searchBox {
          text-align: left;
        }
      }

      .searchResults {
        right: unset;

        transition: opacity 0.2s ease-in-out, margin-left 0.2s ease-in-out 0.1s;

        &.hidden {
          margin-left: -100vw;
          right: -100vw;
        }
      }
    }

    .searchControl {
      margin-right: 0px;
      margin-left: auto;
      justify-content: flex-end;

      .searchBox {
        text-align: right;
        max-width: 240px;
      }
    }

    .searchResults {
      width: 240px;
      transition: opacity 0.2s ease-in-out, margin-right 0.2s ease-in-out 0.1s,
        margin-top 0.2s ease-in-out 0.1s;

      &.hidden {
        margin-right: -100vw;
        margin-top: 36px;
      }
    }
  }

  &.subComponent {
    margin-top: 8px;

    justify-content: flex-end;

    .searchControl {
      flex: 1 1 100%;
    }

    .searchResults {
      left: unset;
      right: unset;
    }
  }

  .searchControl {
    display: flex;

    .searchBox {
      flex: 1 1 100%;
      background-color: $color-dark;
      border-radius: 16px;
      padding: 8px 16px;
      color: $color-light;
      border: 0;
      font-family: Bellefair, serif;
      font-size: 16px;
    }
  }

  .searchResults {
    margin-top: 2px;
    max-height: 256px;
    overflow-y: auto;
    border: 1px solid $color-off-dark;
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px $color-dark;
    position: absolute;
    transition: opacity 0.2s ease-in-out, margin 0.4s ease-in-out 0.1s;
    pointer-events: auto;
    width: calc(640px - 32px);
    z-index: 999;

    &.hidden {
      opacity: 0;
      margin-top: -100vh;
    }

    .searchHeader {
      background-color: $color-light;
      color: $color-dark;
      padding: 8px 16px;
    }

    .searchContents {
      padding-top: 8px;
      padding-bottom: 8px;
      max-height: 204px;
      overflow-y: auto;
      background-color: $color-off-dark;

      .searchResult {
        transition: all 0.2s ease-in-out;

        :global(.search-highlight) {
          background-color: $color-light;
          color: $color-dark;
        }

        &:hover {
          background-color: $color-light;
          color: $color-dark;

          .synonym {
            filter: brightness(150%);

            .lead {
              filter: brightness(150%);
            }
          }
        }

        .itemName {
          display: flex;
          padding: 4px 8px;

          cursor: pointer;

          .restrictedIcon {
            font-size: 14px;
            padding-right: 4px;
            margin-top: 1px;
          }

          .name {
            flex: 1 1 67%;
          }

          .kind {
            flex: 1 1 33%;
            font-style: italic;
            text-align: right;
          }
        }

        .matches {
          padding: 4px 8px 4px 4px;
          border-left: 4px solid $color-midtone;
          margin-left: 8px;
          color: $color-midtone;
        }

        .synonym {
          padding-left: 8px;
          padding-bottom: 4px;
          font-style: italic;
          filter: brightness(75%);

          .lead {
            filter: brightness(75%);
          }
        }
      }
    }
  }

  .itemDetailed {
    margin-top: 16px;
    padding-bottom: 64px;
    user-select: text;
    max-height: calc(100vh - 178px);
    overflow-y: auto;

    .header {
      .itemName {
        font-size: 24px;
      }

      .itemClassifier {
        padding: 2px 0px;
        .itemType {
          font-style: italic;
          font-size: 16px;
        }

        .itemGrade {
          padding-left: 6px;
        }
      }

      .itemBlueprint {
        padding: 2px 0px;
        .clickable {
          cursor: pointer;
          text-decoration: underline;
        }

        .stack {
          padding-left: 8px;
        }
      }
    }

    .partsForFinalProducts {
      padding-top: 16px;

      .title {
        font-size: 18px;
        border-bottom: 1px solid $color-light;
        margin-bottom: 4px;
      }

      .content {
        max-height: 50vh;
        overflow-y: auto;

        .category {
          padding: 4px 0 8px 8px;

          .header {
            padding: 4px 0px 2px 8px;
            border-bottom: 1px solid $color-midtone;
            font-size: 18px;
          }

          .categoryContent {
            padding: 2px 0 0px 4px;
            border-left: 4px solid $color-midtone;

            .part {
              padding: 2px 0;
              display: flex;
              justify-content: space-between;

              .name {
                &.clickable {
                  text-decoration: underline;
                  cursor: pointer;
                }
              }

              .grade {
                padding-left: 4px;
              }
            }
          }
        }
      }
    }

    .blueprintCraftables {
      padding-top: 16px;

      .title {
        font-size: 18px;
        border-bottom: 1px solid $color-light;
      }

      .content {
        width: 100%;

        .finalProductList {
          .finalProduct {
            display: flex;
            align-items: center;
            margin: 4px 0px;

            .grade {
              text-align: center;
              flex: 0 0 48px;
            }

            .name {
              flex: 1 1 100%;

              .clickable {
                cursor: pointer;
                text-decoration: underline;
              }

              .kind {
                padding-left: 8px;
              }

              .stack {
                padding-left: 8px;
              }
            }
          }
        }

        .components {
          padding-top: 8px;
          width: 100%;

          .header {
            border-bottom: 1px solid $color-light;
            padding: 2px 0px;
            font-size: 18px;
          }

          .name {
            width: 75%;
            white-space: nowrap;
            overflow-x: auto;
            padding: 2px 0px;
            cursor: pointer;
            text-decoration: underline;
          }

          .amount {
            width: 5%;
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);
  }
}

@media screen and (max-width: 891px) {
  .container {
    .searchResults {
      max-width: calc(100vw - 240px - 32px);
    }
  }
}

@media screen and (max-width: 721px) {
  .container {
    .searchResults {
      max-width: calc(480px - 32px);
      width: calc(100vw - 32px);
    }
  }
}
