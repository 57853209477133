@import '../../../../theme.scss';
@import '../quadTone.scss';

.skillgrid {
  @include quadTone();
  width: 25%;
  text-align: center;
  border-right: 1px solid $color-off-dark;
  padding: 8px 8px;
  background-color: $color-dark;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;

  .symbol {
    margin-top: -3px;
    margin-bottom: -8px;
  }

  &.overlayActive {
    @include quadTone();

    .bold {
      font-weight: bolder;
      font-size: 18px;
      margin-top: -3px;
      margin-bottom: -8px;
      text-decoration: underline;
    }

    &:hover {
      filter: none;
    }

    &.muted {
      background-color: $color-light;
      color: $color-dark;
    }
  }
  &.muted {
    background-color: $color-dark;
  }

  &:hover {
    filter: brightness(150%);
  }

  &:first-of-type {
    border-right: 1px solid $color-off-dark;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 12px;

    &.overlayActive {
      border-top-left-radius: 0px;
    }
  }

  &:last-of-type {
    border-right: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 12px;

    &.overlayActive {
      border-top-right-radius: 0px;
    }
  }
}
