@import '../theme.scss';

.print {
  table,
  tr,
  th,
  td {
    border: 1px dotted $color-dark;
  }

  td,
  th {
    padding: 2px 8px;
  }
}
