@import 'theme.scss';

body {
  margin: 0;
  font-family: Bellefair, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: $color-off-dark;
  color: $color-light;
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.link-badge {
  color: $color-dark;
  background-color: $color-light;
  padding: 0px 4px;
  border-radius: 4px;
}

.hr-error {
  border: none;
  border-top: 1px solid $color-error;
}

.MuiPaper-root.MuiAppBar-colorPrimary {
  background-color: $color-dark;
  color: $color-light;
}

.main-content {
  display: flex;

  .sidebar {
    margin-top: 44px;
  }

  .routerable {
    width: 100%;
    overflow-x: hidden;
  }
}

.toast-base {
  color: $color-light;
  font-family: Bellefair, serif;
  font-size: 18px;
  text-align: left;
  box-shadow: 0px 0px 20px -5px $color-midtone;
  min-height: 48px;
  margin-bottom: 0;
  margin-top: 8px;

  &.success {
    //background-color: $color-wasteland-4;
    background: linear-gradient(-45deg, $color-wasteland-4 25%, $color-wasteland-2 200%);
    filter: brightness(133%);

    .progress-bar {
      background: linear-gradient(135deg, $color-wasteland-2 0%, $color-light 100%);
    }
  }

  &.error {
    background-color: $color-error-light;
    background: linear-gradient(-45deg, $color-error-light 25%, $color-light 200%);
    color: $color-error;

    .progress-bar {
      background: linear-gradient(135deg, $color-error 0%, $color-error-light 100%);
    }
  }

  .toast-body {
    padding: 4px 16px 4px 12px;
  }
}

.react-datepicker-popper {
  z-index: 25;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $color-dark;
  filter: brightness(200%);
}

.react-toggle--checked .react-toggle-track {
  background-color: $color-anomaly-0;
  filter: brightness(125%);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $color-anomaly-0;
  filter: brightness(150%);
}

.MuiSvgIcon-root.MuiNativeSelect-icon {
  color: $color-light;
  margin-top: 0px 2px 2px 2px;
}

.MuiNativeSelect-select.MuiInputBase-input {
  background-color: $color-dark;
}

.MuiSvgIcon-root.MuiSelect-icon {
  color: $color-light;
}

@media screen and (max-width: 740px) {
  .main-content {
    .sidebar {
      position: relative;
    }
  }
}

@media print {
  .sidebar {
    display: none;
  }
}
