@import '../../theme.scss';

@mixin inputForm {
  background-color: transparent;
  color: $color-light;
  margin: 2px 0px;
  border: 0;
  font-family: Bellefair, serif;
  font-size: 16px;
  padding: 2px 12px;
  margin: 4px 0px;
  width: 100%;
  outline: none;
}

@mixin disabled {

}

@mixin inputWrapper {
  background-color: $color-dark;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-right: 2px;
  border: 1px solid $color-dark;

  &:hover, &:focus-within {
    border: 1px solid $color-light;
  }
}

@mixin addButton {
  border: 0;
  background-color: transparent;
  font-family: Bellefair, serif;
  font-size: 16px;
  text-align: center;
  width: 100%;
  color: $color-dark;
  cursor: pointer;
  padding: 0;
  margin-top: 6px;
  outline: none;
}

.container {

  .scheduler {
    border-bottom: 1px solid $color-light;
    background-color: $color-light;
    font-size: 18px;
    border-radius: 8px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      &:first-of-type {
        padding-top: 8px;
      }

      &:last-of-type {
        padding-bottom: 4px;
      }

      .title {
        flex: 0 0 128px;
        padding-left: 16px;
        color: $color-dark;
      }

      .picker {
        flex: 1 1 75%;
        justify-items: flex-end;

        :global(.react-datepicker-wrapper) {
          margin: 8px auto;
        }
      }

      .input {
        font-family: Bellefair, serif;
        font-size: 14px;
        background-color: $color-dark;
        color: $color-light;
        border-radius: 16px;
        padding: 8px;
        border: 0;
        width: 64px;
        text-align: right;
      }

      .toggleContainer {
        flex: 0 0 80px;
      }

      .description {
        color: $color-dark;
        font-size: 14px;
        padding: 0px 8px;
        margin-bottom: 0;
      }
    }

  }

  .description {
    border-bottom: 1px solid $color-light;
    margin-bottom: 16px;
  }

  .modContent{
    .header {
      font-size: 24px;
      border-bottom: 1px solid $color-light;
      margin-bottom: 8px;
    }
    .modSection {
      .modSectionHeader {
        display: flex;
        margin-bottom: 16px;

        .modSectionToggle {
          flex: 0 0 32px;
          background-color: $color-light;
          color: $color-dark;
          border: 1px solid $color-light;
          margin-right: 2px;
          text-align: center;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
          padding-top: 4px;

          &:hover {
            border: 1px solid $color-light;
            cursor: pointer;
          }
        }
        .modSectionLeft {
          flex: 1 1 100%;
          display: flex;
          @include inputWrapper;

          &.persisted {
            background-color: $color-light;
            border: 1px solid $color-light;
            border-radius: 0;

            .modSectionName {
              color: $color-dark;
              text-decoration: underline;
              text-decoration: underline dashed;
              text-underline-position: under;
            }
          }

          .modSectionName {
            @include inputForm;
          }

          .modCount {
            color: $color-midtone;
            margin-left: -64px;
            margin-right: 8px;
            width: 64px;
            padding-top: 7px;
            text-align: right;
          }
        }

        .modSectionRight {
          flex: 1 1 calc(33% - 15px);
          background-color: $color-light;
          border: 1px solid $color-light;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;

          &.persisted {
            flex: 1 1 33%;
          }

          &.inverse {
            &:hover, &:focus-within {
              border: 1px solid $color-light;
            }

            .modSectionSignupLimit {
              @include inputForm;
              color: $color-dark;
              width: calc(100% - 12px);
              text-align: right;
              text-decoration: underline;
              text-decoration: underline dashed;
              text-underline-position: under;
            }
          }

          &.disabled {
            background-color: $color-midtone;
            border: 1px solid $color-midtone;

            .addSection {
              cursor: not-allowed;
            }
          }

          .addSection {
            @include addButton;
          }


        }
      }

      .mod {
        padding-left: 34px;
        margin-bottom: 16px;

        .modRow {
          display: flex;
          margin-bottom: 4px;

          &.shifted {
            margin-left: 36px;
            border-radius: 0;
          }

          &.modParticipants {
            display: block;
            margin-top: 8px;
          }

          .modToggle {
            flex: 0 0 32px;
            background-color: $color-dark;
            color: $color-light;
            border: 1px solid $color-dark;
            margin-right: 2px;
            text-align: center;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            padding-top: 4px;

            &:hover {
              border: 1px solid $color-light;
              cursor: pointer;
            }
          }

          .modName, .modTimeframe, {
            flex: 1 1 100%;
            @include inputWrapper;

            .modInput {
              @include inputForm;
            }

            &.persisted {
              border-radius: 0;
            }
          }

          .modName {
            display: flex;

            .input {
              flex: 0 0 100%;
            }

            .modInput {
              width: calc(100% - 16px);
            }
          }

          .modLimit {
            display: flex;
            justify-content: space-between;

            .participantsCount {
              width: 48px;
              color: $color-midtone;
              text-align: left;
              margin-top: 7px;
              margin-left: 4px;
              margin-right: -32px;

              .icon {
                font-size: 18px;
                margin-bottom: -4px;
              }
            }
          }

          .modSkillRequirement, .modLimit {
            flex: 1 1 calc(33% + 15px);
            background-color: $color-dark;
            color: $color-light;
            border: 1px solid $color-dark;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;

            &:hover, &:focus-within {
              border: 1px solid $color-light;
            }

            .modParticipationLimit {
              @include inputForm;
              color: $color-light;
              padding: 2px 0px;
              width: calc(100% - 12px);
              text-align: right;
              text-decoration: underline;
              text-decoration: underline dashed;
              text-underline-position: under;
            }
          }

          .modAdd {
            flex: 1 1 calc(33% + 0px);
            background-color: $color-light;
            border: 1px solid $color-light;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;

            &.disabled {
              background-color: $color-midtone;
              border: 1px solid $color-midtone;

              .modAddButton {
                cursor: not-allowed;
              }
            }

            .modAddButton {
              @include addButton;
            }
          }

          .waitlistSeparator {
            padding: 4px 0px;
            .text {
              background-color: $color-off-dark;
            }

            .separator {
              border: 0;
              border-top: 1px solid $color-light;
              margin-top: -10px;
              margin-left: 56px;
            }
          }

          .modParticipant {
            display: flex;
            align-items: center;
            background-color: $color-dark;
            width: 100%;
            margin: 2px 0px;
            padding: 4px 0px;

            .position {
              flex: 0 0 32px;
              text-align: right;
              color: $color-light;
              border-right: 2px solid $color-off-dark;
              padding: 4px;
            }

            .persona {
              flex: 1 1 100%;
              display: flex;
              align-items: center;

              .id {
                flex: 0 0 64px;
                text-align: right;
                margin-right: 8px;

                .paidStatus {
                  margin-left: 4px;

                  &.unpaid {
                    opacity: 0.1;
                  }

                  .icon {
                    font-size: 16px;
                    margin-bottom: -2px;
                  }
                }
              }

              .name {
                flex: 1 1 100%;
              }

              .kick {
                flex: 0 0 64px;
                text-align: center;
                text-decoration: underline;
                cursor: pointer;

                &.kicking {
                  text-decoration: none;
                  color: $color-midtone;
                  cursor: not-allowed;
                }
              }

              .characters {
                .invalidCharacters {
                  color: $color-error;
                }
              }
            }
          }
        }

        .refresh {
          display: flex;
          justify-content: flex-end;
          margin-right: 10px;

          .text {
            margin-right: 4px;
            margin-top: 2px;
            font-size: 16px;
          }

          .refreshButton {
            font-family: Bellefair, serif;
            font-size: 16px;
            background-color: transparent;
            color: $color-light;
            border: 0;
            padding: 0;
            text-decoration: underline;
            cursor: pointer;

            &.disabled {
              cursor: not-allowed;
              text-decoration: none;
              color: $color-midtone;
            }
          }
        }
      }
    }
  }
}

.icon {
  font-size: 14px;
  margin-right: -4px;
}

.skillSelect {
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 0;
  background-color: $color-dark;
  border-radius: 16px;
  padding: 0;
  padding-left: 4px !important;
  text-align: left;
  margin: 0 auto;
  color: $color-light !important;
  font-family: Bellefair, serif !important;
  font-size: 16px !important;

  svg {
    display: none;
  }

  :global(.MuiSelect-select) {
    padding: 6px 0px !important;
    white-space: nowrap !important;
    overflow-x: hidden !important;
    width: calc(100% - 8px);
    text-overflow: clip;
  }
}

@mixin commonTooltip {
  font-size: 16px;
  font-family: Bellefair, serif;
  max-width: 192px;
  padding: 8px 16px;
}

.tooltip {
  @include commonTooltip;
  background-color: $color-dark !important;
  color: $color-light !important;

  &:global(.place-left) {
    &:after {
      border-bottom-color: transparent !important;
      border-left-color: $color-dark !important;
    }
  }
}

.tooltipInverse {
  @include commonTooltip;
  background-color: $color-light !important;
  color: $color-dark !important;

  &:global(.place-left) {
    &:after {
      border-bottom-color: transparent !important;
      border-left-color: $color-light !important;
    }
  }
}

.menu {
  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    margin-left: 0px;
    border-radius: 16px;
    color: $color-light;

    .item {
      font-family: Alegreya, serif;
      padding: 8px 16px;
      min-height: 0px;

      &.disabled {
        color: $color-light;
      }

      &:global(.Mui-selected) {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .modContent {
      .modSection {
        .modSectionHeader {
          .modSectionRight {
            &.inverse {
              .modSectionSignupLimit {
                width: calc(100% - 24px);
              }
            }
          }
        }
      }
    }
  }
}
