@import '../../theme.scss';

.resetPassword {
  font-family: Bellefair, serif;
  margin: 0 auto;
  margin-bottom: 16px;

  .header {
    border-bottom: 1px solid $color-midtone;
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .content {
    margin-bottom: 8px;

    .link {
      cursor: pointer;
      text-decoration: underline;
      color: $color-light;

      &.disabled {
        cursor: not-allowed;
      }
    }

    &.success {
      background-color: $color-success-light;
      color: $color-light;
      padding: 8px 16px;
      border-radius: 16px;
      border-bottom: 0;
    }


    &.error {
      background-color: $color-error-light;
      color: $color-error;
    }
  }

  .announcement {

  }
}
