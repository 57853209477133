@import '../../theme.scss';

.heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 480px;
  margin: 16px auto;

  .text {
    display: inline-flex;
    font-family: Bellefair, serif;
    font-size: 18px;
    color: $color-light;
    background-color: $color-off-dark;
    z-index: 4;
    padding: 4px 8px;
  }

  .line {
    border-bottom: 1px solid $color-midtone;
    height: 8px;
    margin-top: -22px;
    width: 100%;
    z-index: 3;
  }
}
