@import '../../../theme.scss';

.row {
  display: flex;
  justify-content: space-between;
  font-family: Bellefair, serif;
  padding-right: 16px;

  .tooltip {
    z-index: 3000;
    font-family: Bellefair, serif;
    font-size: 16px;
    max-width: 160px;
    padding: 16px;
    border-radius: 16px;
    background-color: $color-light;
    color: $color-dark;

    &:after {
      border-bottom-color: $color-light !important;
    }
  }

  .svg {
    z-index: 61;
    cursor: pointer;
    margin-top: 12px;
  }

  .subrow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4px;

    .hidden {
      color: transparent;
    }

    .svg {
      z-index: 61;
      cursor: pointer;
      margin-right: 12px;
      margin-top: 12px;
    }

    .entry {
      display: block;
      margin-right: 6px;
      width: calc(100% - 48px);

      input {
        color: $color-light;
        text-align: right;
        font-family: Bellefair, serif;
        padding-right: 42px;
        margin-bottom: 4px;

        &:disabled {
          text-decoration: line-through !important;
          font-weight: normal !important;
          color: $color-midtone;
        }
      }
    }

    &.highlighted {
      input {
        font-weight: bolder;
        text-decoration: underline;
        z-index: 60;
      }

      background: linear-gradient(90deg, $color-dark 0%, $color-gray 200%);
      border-radius: 48px;
    }

    .metadata {
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 32px);
      margin: 0 auto;
      font-size: 14px;
      margin-top: -12px;
      margin-right: 36px;
      filter: brightness(80%);

      .metaElement {
        margin: 0 8px;
      }
    }
  }
}



.delete {
  cursor: pointer;
  &.hidden {
    display: none;
  }
}

.undelete {
  cursor: pointer;
  &.hidden {
    display: none;
  }
}
