@import '../theme.scss';

.page {
  width: 720px;
  background-color: white;
  color: black;
  font-family: Alegreya, serif;
  font-size: 12px;
  margin: 0 auto;

  &.notPrintable {
    display: none;
  }

  .twoColumns {
    display: flex;
    justify-content: space-around;

    .left {
      margin: 16px auto 0 0;
      width: calc(50% - 16px);
    }

    .right {
      margin: 16px 0 0 auto;
      width: calc(50% - 16px);
    }
  }

  .note {
    margin: 8px auto 0px auto;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }

  .placebo {
    font-size: 24px;
    text-align: center;
    margin: 16px;
    padding: 16px;

    &.sample {
      margin-top: 80px;
    }
  }
}

.table {
  width: calc(100%);
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 8px;

  .filler {
    width: 70%;
    border: 0px;
    text-align: center;
  }

  .staffInitials {
    width: 30%;
    height: 24px;
  }

  .row {
    height: 16px;
    border: 1px solid $color-midtone;
  }
}

@media screen {
  .page {
    width: 850px;
    .twoColumns {
      display: none;
    }

    .note {
      display: none;
    }
  }
}

@media print {
  .page {
    display: contents;
    margin: 0;
    padding: 0;

    .twoColumns {
      padding-right: 1px;
    }
    .placebo {
      display: none;
    }
  }
}
