@import '../../theme.scss';

.monthHeading {
  margin-top: 16px;
  display: flex-inline;
  margin-bottom: 8px;

  .placebo {
    border-bottom: 1px solid $color-light;
    width: 100%;
    max-width: 480px;
    margin-top: -10px;
    z-index: 2;
    position: relative;
  }

  .text {
    background-color: $color-off-dark;
    position: relative;
    z-index: 3;
    padding-right: 16px;
    display: inline;
  }
}

.eventMonth {
  display: flex;
  flex-wrap: wrap;

  .eventWeek {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $color-midtone;
    align-items: center;

    &.thisWeek {
      background: linear-gradient(
        90deg, 
        $color-light 0, 
        $color-light calc(10% + 12px), 
        $color-midtone calc(10% + 12px), 
        $color-midtone 100%
      );

      .weekDay {
        color: $color-dark;
      }
    }

    .weekDay {
      flex: 0 0 10%;
      padding: 4px 16px 4px 0;
      text-align: right;
    }

    .cell {
      padding: 4px 0px;
      display: flex;
      flex-wrap: wrap;

      &.travel {
        width: 60%;
        flex: 0 1 60%;
      }

      &.home {
        width: 30%;
        flex: 0 0 30%;
        padding-left: 4px;
        margin-right: -4px;
      }

      &.noHome {
        &.home {
          display: none;
        }

        &.travel {
          width: 90%;
        }
      }
    }
  }
}
