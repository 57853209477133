@import '../theme.scss';

.locationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  margin-right: 2px;
  max-width: 480px;

  .buttonGroup {
    display: flex;

    .button {
      background-color: $color-light;
      border-radius: 16px;
      color: $color-dark;
      margin: 0px 6px;
      padding: 8px 16px;
      text-align: center;
      cursor: pointer;

      &:hover {
        filter: brightness(125%);
      }

      &.disabled {
        background-color: $color-midtone;
        color: $color-dark;
        cursor: not-allowed;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.hr {
  border-top: 1px solid $color-midtone;
  margin-top: 15px;
  padding-top: 5px;
}

.page {
  margin: 64px auto auto auto;
  max-width: 480px;
  width: 100vh;
}

.ul {
  list-style-type: none;
  padding: 0;
}
