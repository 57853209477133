@import '../theme.scss';

.container {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 0 8px;
  padding: 2px 0px 0px 0px;

  &.staging {
    background-color: $color-error;
  }

  .title {
    padding: 11px 4px 7px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $color-light;
    user-select: none;
    flex: 1;

    .login {
      cursor: pointer;
      color: $color-light;
      text-decoration: none;
    }

    .appTitle {
      display: none;
    }
  }

  .logout {
    border: 1px solid $color-midtone;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 6px;
    display: flex;
    width: 10%;
    padding: 6px 10px 0px 8px;
    font-size: 16px;
    height: 24px;
    min-width: 48px;
  }

  .characterSelectorContainer {
    text-align: right;
    margin-right: 0;
    flex: 2;
  }

  .burger {
    flex: initial;
    padding: 12px;
    margin-top: -8px;
    margin-right: -12px;
    margin-bottom: -8px;
  }
}

@media print {
  .container {
    display: none;
  }
}

@mixin inverted() {
  background-color: $color-light;

  :global(.MuiListItemIcon-root) {
    color: $color-dark;
  }

  :global(.MuiListItemText-root) {
    :global(.MuiTypography-root) {
      color: $color-dark;
    }
  }
}

.swipeable {
  :global(.MuiPaper-root) {
    background-color: $color-dark;

    :global(.MuiButtonBase-root) {
      &:hover {
        @include inverted();
      }

      &.selected {
        @include inverted();
      }

      :global(.MuiListItemIcon-root) {
        min-width: 36px;
        color: $color-light;
      }

      :global(.MuiListItemText-root) {
        :global(.MuiTypography-root) {
          font-family: 'Veteran Typewriter', serif;
          color: $color-light;
        }
      }
    }
  }
}

.sidebarIgnoreable {
  text-align: center;
}

@media screen and (min-width: 740px) {
  .container {
    max-width: calc(100vw - 64px);
    width: 100%;
    margin: 0 auto;

    .burger {
      display: none;
    }

    .title {
      margin-left: -28px;

      .login {
        display: none;
      }

      .appTitle {
        display: block;
        margin-left: -4px;
      }
    }
  }
}