@import '../../theme.scss';

.container {
  max-width: 480px;
  margin: 0 auto;

  .link {
    text-decoration: underline;
    cursor: pointer;
    padding: 0px 6px;
  }
}
