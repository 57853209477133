@import '../theme.scss';

.container {
  .fixedHeader {
    .eventLinks {
      max-width: 480px;
      margin: 0 auto;
      margin-top: 8px;
    }

    .controls {
      justify-content: space-between;
      font-family: Bellefair, serif;
      color: $color-light;
      padding: 8px 8px 16px 8px;
      border-bottom: 1px solid $color-light;

      .columned {
        display: flex;
        max-width: 480px;
        margin: 0 auto;
        align-items: center;

        .left {
          flex: 0 1 67%;

          .control {
            margin-bottom: 8px;

            &:last-of-type {
              margin-bottom: 0;
            };
          }
        }

        .right {
          flex: 0 1 33%;

          .control {
            justify-content: flex-end;
            margin-right: 0;
            margin-bottom: 10px;
            margin-top: 0;

            &:last-of-type {
              margin-bottom: 0;
            };
          }
        }

        .control {
          margin-right: 16px;
          display: flex;

          .toggleLabel {
            font-size: 16px;
            margin-top: 4px;
            margin-left: 8px;
          }

          .dropdownWrapper {
            margin-right: -48px;
          }

          .link {
            color: $color-light;
          }
        }

      }

    }
  }

  .boardColumn {
    display: flex;
    margin: 0 auto;

    .playerList {
      flex: 1 1 25%;
      max-width: 240px;
      box-shadow: 0px 20px 20px 0px $color-dark;
      height: 100vh;

      .title {
        background-color: $color-light;
        color: $color-dark;
        padding: 16px;

        .unfiltered {
          font-size: 20px;
        }

        .filtered {
          .clearFilter {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .content {
        cursor: pointer;
        height: 100%;
        overflow-y: auto;

        .noMatch {
          text-align: center;
          padding: 4px 16px;
        }

        .playerRow {
          display: flex;
          padding: 2px 0px;

          &:hover {
            background-color: $color-light;
            color: $color-dark;
          }

          &.highlighted {
            background-color: $color-light;
            color: $color-dark;
            cursor: not-allowed;
          }

          .playerID {
            flex: 0 0 52px;
            text-align: right;
            padding-right: 8px;
          }

          .playerName {
            flex: 0 1 90%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            padding-right: 4px;

            &.notYetPaid {
              color: $color-midtone;
            }
          }
        }
      }
    }

    .characters {
      max-height: calc(100vh - 116px);
      flex: 1 1 75%;
      overflow-y: scroll;

      .row {
        display: flex;
        justify-content: center;

        &:first-of-type {
          margin-top: 16px;
        }

        &:last-of-type {
          margin-bottom: 32px;
        }

        .checkbox {
          margin-left: 16px;
        }

        .sheet {

        }
      }
    }
  }

}

@media print {
  .container {
    display: block;

    .fixedHeader {
      display: none;
    }

    .boardColumn {
      .playerList {
        display: none;
      }

      .characters {
        max-height: none;
        height: auto !important;
        overflow-y: hidden;
        overflow-x: hidden;

        .row {
          break-after: always;
          break-before: always;
          margin: 0 !important;

          .checkbox {
            display: none;
          }

          .sheet {
            width: 100%;
          }

          &.notPrinted {
            display: none;
          }
        }

        .controls {
          display: none;
        }

        .eventLinks {
          display: none;
        }
      }
    }
  }
}
