@import '../theme.scss';

.container {
  margin-top: 64px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding: 0px 8px;
}
