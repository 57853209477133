@import '../theme.scss';

.container {
  background-color: $color-light;
  color: $color-dark;
  padding: 16px;
  margin-top: -42px;
  font-family: Bellefair, serif;
  border-radius: 16px;

  .noEvents {
    color: $color-midtone;
  }

  .placebo {
    font-size: 16px;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 8px;
  }

  .contents {
    max-height: 50vh;
    overflow-y: scroll;

    .monthGroup {
      .groupHeader {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid $color-dark;
        margin-top: 8px;
        margin-bottom: 8px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      .groupContent {
        .eventRow {
          display: flex;
          justify-content: space-between;
          color: $color-dark;
          margin: 4px 0px;

          .link {
            color: $color-dark;

            &.edit {
              .text {
                max-width: 150px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0px 24px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.popper {
  z-index: 1301;
}
