@import '../../theme.scss';

.container {
  margin-top: 8px;
  
  .title {
    font-size: 22px;
    border-bottom: 1px solid $color-light;
    text-align: center;
    margin-bottom: 8px;
  }

  .toggles {
    display: flex;
    justify-content: space-around;

    .half {
      display: flex;
      justify-content: center;
      width: calc(100% - 32px);

      .label {
        margin-top: 2px;
        margin-left: 8px;
      }
    }
  }
}
