@import '../theme.scss';

.container {
  font-family: Bellefair, serif;
  color: $color-light;
  max-width: 480px;
  padding: 0px 4px;
  margin: 0 auto;
  margin-top: 64px;

  .title {
    font-size: 20px;
  }

  .header {
    border-top: 1px solid $color-light;
    border-bottom: 1px solid $color-light;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 2px 0px;
    font-size: 18px;
    align-items: center;
}

  .content {
    max-height: 60vh;
    overflow-y: auto;
    .row {
      text-align: left;
      display: flex;
      justify-content: space-between;
      padding: 2px 0px;
      border-bottom: 1px solid $color-midtone;
    }
  }

  .logout {
    margin-top: 8px;
    font-family: Bellefair, serif;
    font-size: 16px;
    display: block;
    border-radius: 16px;
    padding: 8px 16px;
    width: 100%;
    background-color: $color-light;
    color: $color-dark;
    cursor: pointer;

    &:hover {
      filter: brightness(125%);
    }
  }

  .browser {
    flex: 0 1 25%;
  }

  .os {
    flex: 0 1 25%;

  }

  .timestamp {
    flex: 0 1 25%;
  }

  .recentActivity {
    flex: 0 1 25%;
  }
}
