@import '../../theme.scss';

@mixin invert {
  background-color: $color-light;
  color: $color-dark;

  .acq {
    color: $color-dark;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .statControl {
    width: 25%;
    max-width: 32px;
    text-align: center;
    padding: 2px 2px;
    font-size: 28px;
    background-color: $color-off-dark;
    color: $color-light;
    cursor: pointer;
    user-select: none;

    &.invert {
      @include invert();
    }

    &:hover {
      filter: brightness(125%);
    }

    &.left {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      border-right: 1px solid $color-midtone;
    }

    &.right {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border-left: 1px solid $color-midtone;
    }

    &.disabled {
      color: transparent;
      cursor: default;
      &:hover {
        filter: none;
      }
    }
  }

  .statLead {
    background-color: $color-off-dark;
    color: $color-light;
    padding: 12px 4px 0px 4px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    &.invert {
      @include invert();
    }

    .acq {
      margin-top: -9px;
      font-family: 'Bellefair', serif;
      color: $color-light;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      :global(.MuiInputBase-input) {
        font-weight: bolder;
        font-size: 20px;
        text-decoration: underline;
        width: 32px;
        text-align: center;
      }
    }
  }
}
