@import '../theme.scss';

.page {
  width: 750px; // 8.5" * 100 DPI
  //height: 1100px; // 11" * 100 DPI
  padding: 50px;
  background-color: white;
  color: black;
  font-family: Alegreya, serif;
  font-size: 12px;
  margin-bottom: 16px;

  &.muted {
    background-color: $color-midtone;
  }
}

.table {
  width: 100%;
  border: 1px solid $color-midtone;
  border-collapse: collapse;

  :global(tr) {
    :global(td) {
      padding: 2px 8px;
      border: 1px solid $color-midtone;
    }

    :global(th) {
      padding: 4px 8px;
      border: 1px solid $color-midtone;
    }
  }
}

.persona {
  .icon {
    font-size: 18px;
    margin-bottom: -4px;
    margin-right: 4px;
  }
  .table {
    border: 0px;

    :global(tr) {
      :global(td) {
        border: 0px;
        padding: 0px;
      }
    }

    .text {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;

      &.large {
        font-size: 20px;
      }

      .iconified {
        margin-bottom: -5px;
        margin-right: 4px;
      }
    }
  }
}

.duo {
  display: flex;

  .builds {
    padding-right: 4px;
    padding-top: 8px;

    .notes {
      border: 1px solid $color-midtone;
      padding: 4px 8px;
      height: 80px;
    }

    .stats {
      .lead {
        font-size: 12px;
      }

      .gridLead {
        font-size: 14px;
        font-weight: bold;
      }

      .text, .statColumn {
        font-size: 12px;
        padding-top: 0px;
        padding-bottom: 0px;

        &.padded {
          padding-top: 2px;
          padding-bottom: 2px;
          font-weight: bold;
        }
      }

      .statColumn {
        width: 40px;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;

        &.buildSubtotal {
          width: 60px;
        }
      }
    }

    .bimodal {
      display: flex;

      .miscColumn {
        width: 150px;
        margin-top: 4px;

        .freeForm {
          margin-top: 4px;
          border: 0;
          min-height: 48px;

          .content {
            padding: 0;

            .noteSection {
              .lead {
                font-weight: bold;
              }

              .subSection {
                &:last-of-type {
                  border-bottom: 0;
                }

                border-bottom: 1px solid $color-dark;
              }
            }
          }
        }

        .cea {
          padding-top: 4px;

          :global(th) {
            font-size: 14px;
          }
        }

        .lores, .fractures {
          padding-top: 4px;

          :global(th) {
            font-size: 14px;
          }

          .xMark {
            width: 12px;
            text-align: center;
          }
        }

        .caps {
          padding-top: 8px;

          font-weight: bold;
        }
      }

      .skillsColumn {
        width: calc(100% - 158px);
        margin-left: auto;

        .skills {
          margin-top: -1px;

          .asterisk {
            text-align: right;
            margin-top: 8px;
            margin-bottom: 4px;
          }

          .divider {
            height: 1px;
          }

          .fixedSkillLevel {
            padding: 0 2px 0 0;
            width: 20px;

            &.resolveLocked {
              &:before {
                content: '{';
              }

              &:after {
                content: '}';
              }
            }
          }

          .xMark {
            width: 8px;
          }

          .skillColumn {
            text-align: center;
            font-weight: bold;

            &.fixedSkillLevel {
              .wrapper {
                font-size: 14px;
                padding: 0px 3px;
              }
            }

            &.sigma {
              width: 30px;
              padding: 0;
            }

            &.rotated {
              position: relative;
              width: 14px;
              max-width: 14px;
              vertical-align: bottom;
              padding-bottom: 4px;
              font-size: 14px;

              .wrapper {
                transform: rotate(-90deg);
                position: relative;
                margin-right: 12px;
                text-align: center;
                white-space: nowrap;
                width: 100%;
              }
            }
          }

          .skillName {
            padding: 0px 4px;

            .sharedCell {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .basicName {
                flex: 1 0 50%;
              }

              .pfaName {
                font-weight: bold;
                text-align: right;
              }
            }
          }

          .pfa {
            font-weight: bold;
          }

          :global(tfoot) {
            .skillColumn {
              text-align: right;
              padding: 0px 4px;

              &.total {
                text-align: right;
                font-size: 14px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .misc {
    padding-left: 4px;
    padding-top: 8px;
    margin-right: -1px;

    .eventInfo {
      :global(table) {
        :global(th) {
          font-size: 14px;
        }

        :global(td) {
          white-space: nowrap;
          max-width: 0px;
        }
      }

      .text {

      }
    }

    .shifts {
      padding-top: 8px;
      :global(th) {
        font-size: 14px;
      }

      :global(td) {
        white-space: nowrap;
        max-width: 0px;
      }
    }

    .buildComponents {
      padding-top: 8px;

      .rightAlign {
        font-size: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .newLearnings {
      margin-top: 8px;

      .placeholder {
        height: 39px;
      }
    }
  }
}

.leftSide {
  width: 70%;
}

.rightSide {
  width: 30%;
}

.leftHalf {
  width: 50%;
}

.rightHalf {
  width: 50%;
}

.text {
  &.rightAlign {
    text-align: right;
  }

  &.bold {
    font-weight: bold;
  }

  &.verticalBottom {
    vertical-align: bottom;
  }

  &.topAlign {
    vertical-align: top;
  }

  &.leftAlign {
    text-align: left;
  }

  &.large {
    font-size: 20px;
  }

  &.blanked {
    color: transparent;
    background-color: $color-light;
  }
}

.fixedSkillLevel {
  width: 10px;
}

@media screen {
  .duo {
    display: none;
  }
}

@media print {
  .wrapper {
    display: block;
    padding-right: 1px;

    .page {
      display: contents;
      padding: 0;
      background-color: white;

      &.muted {
        background-color: white;
      }
    }

    .break {
      break-after: page;
    }
  }
}
