@import '../../../theme.scss';

.container {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 6;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 4px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.noHorizontalScroll {
  overflow-x: hidden;
}

.toastWarning {
  background-color: $color-midtone !important;
  opacity: 0.95;

  .toastWarningBody {
    font-family: Alegreya, serif;
    color: $color-light;
  }
}
