@import '../../theme.scss';

.xpbar {
  text-align: left;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  color: $color-light;
  flex-wrap: wrap;
  margin: 0 0px 2px 2px;
  padding-top: 10px;
  width: calc(50% - 2px);
  border-radius: 16px;
  background-color: $color-dark;
  user-select: none;
  cursor: pointer;

  .text {
    padding-left: 7%;
    color: $color-light;

    &.error {
      color: $color-error;
    }
  }

  .placeholder {
    position: absolute;
    margin-top: -26px;
    margin-left: 0px;
    height: 34px;
    width: 120px;
    border-right: 1px solid $color-gray;
    border-radius: 16px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: flex-end;

    svg {
      display: none;
      padding-top: 4px;
      margin-right: -13px;

      &.warned {
        display: block;
      }
    }
  }
}

.overwrap {
  overflow-y: scroll;
  max-height: 67vh;
  padding-right: 16px;
  margin-right: -16px;
  padding-top: 8px;
  padding-bottom: 24px;
}

.table {
  width: 100%;
  padding: 0;
  font-size: 16px;

  .numeric {
    text-align: right;
    font-weight: bolder;
    width: 48px;
  }

  .total {
    border-top: 1px solid $color-dark;
    padding-top: 5px;
  }
}

.close {
  background-color: $color-midtone;
  color: $color-light;
  margin: 0px -16px -16px -16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-align: center;
  cursor: pointer;
  padding: 6px 0px 8px 0px;

  .downshift {
    margin-bottom: -6px;
    margin-left: -6px;
  }
}

.violations {
  margin-top: 12px;
  text-align: center;
  font-size: 18px;
}

.violationMessage {
  margin-top: 4px;
  font-size: 16px;
  background-color: $color-error-light;
  color: $color-error;
  border-left: 4px solid $color-error;
  padding: 4px 4px 4px 8px;
}
