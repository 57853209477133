@import '../../theme.scss';

.container {
  margin-bottom: 64px;

  .row {
    &.muted {
      opacity: 0.4;
    }

    &.hidden {
      display: none;
    }

    .firstRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .branchSelect {
        flex: 1 1 25%;
      }

      .reason {
        flex: 1 1 60%;
        margin: 0px 0px 8px 0px;

        .editable {
          padding-left: 12px;
          padding-right: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: 1px solid $color-off-dark;
          width: calc(100% - 14px);

          &:hover {
            border-right-color: $color-light;
          }
        }
      }

      .amount {
        flex: 1 1 10%;
        margin: 0px 8px 8px 0px;

        .editable {
          text-align: right;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 1px solid $color-off-dark;

          &:hover {
            border-left-color: $color-light;
          }
        }
      }

      .buttons {
        flex: 1 1 5%;
        text-align: center;
        font-size: 24px;
        margin-top: -8px;
        padding: 0px 0px 0px 8px;
      }
    }

    .metadata {
      margin-top: -6px;
      margin-bottom: 12px;
    }
  }

  .readOnlyRow {
    display: flex;
    margin: 4px 0px;

    &.first-of-type {
      margin-top: 0;
    }

    &.hidden {
      display: none;
    }

    .branch {
      flex: 0 0 48px;
      text-align: right;
    }

    .date {
      flex: 0 0 88px;
      text-align: right;
    }

    .reason {
      flex: 0 1 80%;
      padding: 0px 8px;
    }

    .amount {
      flex: 0 1 32px;
      text-align: right;
    }
  }

  .noCaps {
    margin-top: 8px;
  }

  .list {
    margin-top: 16px;

    .header {
      border-bottom: 1px solid $color-light;
      font-size: 20px;
      margin-bottom: 8px;
    }

    .row {
      margin-bottom: -6px;
    }

    .branchFilters {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-light;
      margin-bottom: 8px;

      .branch {
        display: flex;
        font-size: 20px;
        cursor: pointer;
        margin-bottom: 8px;

        color: $color-dark;
        border-radius: 16px;
        font-size: 16px;
        margin: 0px 8px;
        margin-bottom: 8px;

        &.muted {
          .name, .badge {
            background-color: $color-midtone;
          }
        }

        &:hover {
          filter: brightness(125%);
        }

        .name {
          margin-right: 1px;
          background-color: $color-light;
          padding: 2px 12px;
          padding-right: 8px;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        .badge {
          font-weight: bold;
          background-color: $color-light;
          padding: 2px 12px;
          padding-left: 8px;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .clearFilter {
        text-decoration: underline;
        cursor: pointer;
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }
}

.editable {
  background-color: $color-dark;
  color: $color-light;
  border: 1px solid $color-dark;
  padding: 8px;
  border-radius: 16px;
  font-size: 14px;
  font-family: Bellefair, serif;
  width: 100%;

  &:focus {
    outline: 0;
  }

  &:hover {
    border-color: $color-light;
    cursor: pointer;
  }
}

.icon {
  margin-top: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(125%)
  }
}

