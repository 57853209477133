@import '../../theme.scss';

.plan {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 2px 0px;

  .numeric {
    text-align: right;
  }

  &.stat {
    .numeric {
      margin-top: -2px;
    }
  }
}

.heading {
  font-size: 18px;
  border-bottom: 1px solid $color-dark;
  text-align: center;
  margin-top: 8px;
  padding-bottom: 4px;
  margin-bottom: 2px;
}

.footer {
  font-size: 18px;
  border-top: 1px solid $color-dark;
  text-align: center;
  padding-top: 4px;
}
