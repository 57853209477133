@import '../../theme.scss';

.container {
  border-top: 1px solid $color-midtone;
  margin-top: 16px;

  .button {
    margin-top: 16px;
    background-color: $color-light;
    color: $color-dark;
    text-align: center;
    padding: 8px 16px;
    border-radius: 16px;
    cursor: pointer;

    &:hover {
      filter: brightness(125%);
    }
  }
}
