@import '../../theme.scss';

.container {
	.control {
		display: flex;
		margin: 16px 0px;

		.userId {
			flex: 1 1 100%;
			font-family: Bellefair, serif;
			background-color: $color-dark;
			font-size: 16px;
			color: $color-light;
			padding: 8px 16px;
			border: 0;
			border-radius: 16px;
		}

		.performSearch {
			flex: 0 0 80px;
			margin-left: 16px;
			border-radius: 16px;
			padding: 8px;
			border: 0;
			font-family: Bellefair, serif;
			font-size: 16px;
			cursor: pointer;

			&:hover {
				filter: brightness(125%);
			}

			&.disabled {
				background-color: $color-midtone;
				cursor: not-allowed;

				&:hover {
					filter: none;
				}
			}
		}
	}
	.user {
		margin-top: 16px;
		padding-bottom: 64px;

		.title {
			display: flex;
			justify-content: space-between;

			.userName {
				font-size: 20px;
			}

			.filter {
				display: flex;
				align-items: center;

				.filterText {
					padding-right: 8px;
				}
			}
		}

		.character {
			margin-top: 16px;

			.name {
				border-bottom: 1px solid $color-midtone;
				margin-bottom: 4px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				.left {
					display: flex;

					.icon {
						font-size: 16px;
						margin: 0px 4px;
					}
				}

				.icon {
					font-size: 20px;
				}
			}

			.inventory {
				&.zeroQuantity {
					color: $color-midtone;
				}

				&.negativeQuantity {
					color: $color-combat-3;
					filter: brightness(200%);
				}

				.aggregate {
					display: flex;
					padding: 2px 0px;
					cursor: pointer;

					&:hover {
						background-color: $color-light;
						color: $color-dark;

						.itemName {
							.uniqueHint {
								color: $color-combat-1;
							}
						}
					}

					&.expanded {
						background-color: $color-light;
						color: $color-dark;

						.itemName {
							.uniqueHint {
								color: $color-combat-1;
							}
						}
					}

					.kind {
						flex: 0 0 96px;
						margin-right: 8px;
					}

					.itemName {
						flex: 1 1 100%;
						.firstRow {
							display: flex;
						}

						.uniqueHint {
							color: $color-anomaly-0;
							filter: brightness(200%);
						}
					}

					.grade {
						flex: 0 0 64px;
					}

					.amount {
						flex: 0 0 32px;
						text-align: right;
					}
				}

				.details {
					border-left: 4px solid $color-light;
					padding: 8px 0px 0px 8px;
				}
			}
		}
	}
	.recency {
		.clickable {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
