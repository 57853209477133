@import '../../theme.scss';

@mixin amount {
  flex: 0 0 32px;
  text-align: right;
  background: transparent;
  color: $color-light;
  padding: 0;
  border: 0;
  font-family: Bellefair, serif;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
  font-size: 16px;
  width: 48px;

  &:focus {
    outline: none;
  }
}

@mixin transaction-row {
  .component {
    .mainControl {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-bottom: 2px;

      &.hoverable {
        &:hover {
          .name {
            .augmentationAndEmblem {
              .augmentations {
                display: block;
              }

              .registeredItemSelector {
                display: block;
              }

              .emblem {
                display: block;
              }
            }

            .augmentationOnly {
              .augmentations {
                display: block;
              }

              .registeredItemSelector {
                display: block;
              }
            }
          }
        }
      }

      &.left {
        &.annotationOpen {
          .name {
            .augmentationAndEmblem {
              .augmentations {
                display: block;
              }

              .registeredItemSelector {
                display: block;
              }

              .emblem {
                display: block;
              }
            }

            .augmentationOnly {
              .augmentations {
                display: block;
              }

              .registeredItemSelector {
                display: block;
              }
            }
          }
        }
      }

      .removeComponent {
        flex: 0 0 16px;
        cursor: pointer;
      }

      .applicator {
        display: flex;

        .split {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .name {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 25%;
        align-items: flex-start;

        // .augmentationAndEmblem {
        //   margin-left: -260px;
        // }

        &.leftFlex {
          flex: 1 1 100%;
        }

        &.pushLeft {
          .augmentationAndEmblem {
            // margin-left: -260px;
            // margin-left: -120px;
          }
        }

        &.blueprint {
          color: $color-anomaly-0;
          filter: brightness(200%);
        }

        .uniqueItem {
          max-width: 320px;

          .uniqueItemLeading {
            color: $color-anomaly-0;
            filter: brightness(200%);
          }
        }
      }

      .taxonomySelect {
        display: flex;

        .augmentations {
          margin-top: -32px;
          margin-left: -64px;
        }

        &:hover {
          .augmentations {
            display: block;
          }
        }
      }

      .nonEditable {
        margin-right: 14px;
        width: 32px;
        text-align: right;
      }
    }

    .splitControl {
      padding: 8px 0px 4px 0px;
      margin-bottom: 8px;
      margin-right: 0px;
      border-right: 4px solid $color-midtone;
      border-bottom: 1px dashed $color-midtone;

      .split {
        display: flex;
        justify-content: flex-end;
        margin-right: -4px;

        .removeSplit {
          cursor: pointer;
        }

        .amount {
          @include amount;
        }
      }
    }
  }
}

@mixin augmentations {
  z-index: 999;
  /*position: absolute;*/
  display: none;
  background-color: $color-off-dark;
  box-shadow: 0px 4px 20px 0px $color-dark;
  padding: 8px 0px;
  border-radius: 8px;
  text-align: center;
  width: 96px;
  /*margin-left: -100px;*/
  /*margin-top: -8px;*/

  .augmentation {
    padding: 2px 16px;
    cursor: pointer;

    &.currentAugmentation {
      background-color: $color-light;
      color: $color-dark;
    }

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }
  }
}

@mixin registeredItemSelector {
  z-index: 999;
  display: none;
  background-color: $color-off-dark;
  box-shadow: 0px 4px 20px 0px $color-dark;
  padding: 8px 0px;
  border-radius: 8px;
  width: 224px;
  max-height: 33vh;
  overflow-y: auto;

  .registeredItem {
    padding: 2px 16px;
    cursor: pointer;

    &.currentSelection {
      background-color: $color-light;
      color: $color-dark;
    }

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }
  }
}

.addMoreSplit {
  text-align: right;
  margin: 8px 0px;
  margin-right: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.unsplit {
  text-align: right;
  margin: 8px 0px;
  margin-right: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.augmentationOnly {
  position: absolute;

  // &.postal {
  //   margin-top: 24px;
  // }

  .augmentations {
    @include augmentations;
  }

  .registeredItemSelector {
    @include registeredItemSelector;
  }
}

.augmentationAndEmblem {
  display: flex;
  margin-left: -180px;

  &.noMargin {
    margin-left: 96px;
  }

  .augmentations {
    @include augmentations;
  }

  .registeredItemSelector {
    @include registeredItemSelector;
    // position: absolute;
  }

  .emblem {
    display: none;

    &.visible {
      display: block;
    }

    .emblemText {
      border-radius: 4px;
      background-color: $color-dark;
      color: $color-light;
      width: 320px;
      height: 64px;
      margin: 8px 0px;
      resize: none;
    }
  }
}

.preEmblemBreak {
  flex-basis: 100%;
  height: 0;
}

.postalSwitcher {
  margin: 8px 0px;

  .postalSwitcherClickable {
    text-decoration: underline;
    cursor: pointer;
  }
}

.button {
  font-family: Bellefair, serif;
  border-radius: 16px;
  background-color: $color-light;
  color: $color-dark;
  font-size: 16px;
  padding: 4px 16px;
  border: 0;
  cursor: pointer;

  &.disabled {
    background-color: $color-midtone;
    cursor: not-allowed;

    &:hover {
      filter: none;
    }
  }

  &:hover {
    filter: brightness(125%);
  }
}

.amount {
  @include amount;
}

.repeatTop {
  max-width: 320px;
  margin: 0 auto;
  text-align: center;

  .repeat {
    text-align: center;
    border-bottom: 1px solid $color-midtone;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .repeatInfo {
    text-align: center;
    margin-bottom: 16px;
  }
}

.container {
  min-height: 50vh;

  .blueprintSearch {
  }

  .description {
    margin-top: 16px;

    .textarea {
      width: calc(100% - 18px);
      background-color: $color-dark;
      color: $color-light;
      padding: 8px;
      border-radius: 4px;
    }
  }

  .executionControl {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-top: 1px solid $color-light;
    margin-top: 32px;
    padding-top: 16px;

    .reset {
    }

    .execute {
      flex: 0 0 40%;

      .repeat {
        margin: 8px 0px;
        text-align: right;
      }

      .executeButton {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .globalApplicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .applicator {
      flex: 1 1 100%;
    }

    .apply {
      text-decoration: underline;
      cursor: pointer;
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 2px;

      &.left {
        text-align: right;
        flex: 1 1 100%;
      }

      &.right {
        flex: 1 1 100%;
      }

      &.inactive {
        cursor: not-allowed;
        color: $color-midtone;
        text-decoration: none;
        pointer-events: none;
      }

      .partner {
        color: $color-light;
      }
    }
  }

  .craftingControl {
    margin-top: 16px;

    .blueprint {
      font-size: 20px;
      text-align: center;
    }

    .slider {
      padding-top: 8px;
      max-width: 240px;
      margin: 0 auto;
    }

    .progression {
      margin: 0 auto;
      margin-top: 8px;
      text-align: center;
    }
  }

  .transactionTable {
    margin: 0 auto;
    margin-top: 16px;
    max-width: 640px;
    width: calc(100vw - 48px);

    .lowerLevelItems {
      @include transaction-row;

      border-bottom: 1px solid $color-midtone;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }

    .right {
      text-align: right;
      border-bottom: 1px solid $color-midtone;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }

    .components {
      @include transaction-row;

      &.bidirectional {
        .component {
          .mainControl {
            flex-direction: row-reverse;
            justify-content: flex-start;

            .nonEditable {
              flex: 1 1 100%;
              /*text-align: right;*/
            }

            .amount {
              flex: 1 1 100%;
            }

            .name {
              flex: 1 1 100%;
            }

            .removeComponent {
              flex: 0 0 16px;
            }
          }

          .splitControl {
            .split {
              flex-direction: row-reverse;
              justify-content: flex-start;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .upperLevelItems {
      @include transaction-row;

      border-top: 1px solid $color-midtone;
      padding-top: 8px;
      margin-top: 4px;

      .component {
        .mainControl {
          justify-content: flex-start;

          .removeComponent {
            flex: unset;
            margin-right: 8px;
          }

          .amount {
            flex: 0 0 16px;
          }

          .name {
            max-width: 320px;
          }

          .split {
            justify-content: flex-start;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .splitControl {
          border-left: 4px solid $color-midtone;
          border-right: 0;
          padding-left: 8px;

          .split {
            justify-content: flex-start;
            text-decoration: underline;
            cursor: pointer;
          }

          .addMoreSplit {
            text-align: left;
          }

          .unsplit {
            text-align: left;
          }
        }
      }

      .augmentationAndEmblem {
        margin-left: 0;
      }
    }
  }

  .successfulTransaction {
    background-color: $color-wasteland-3;
    color: $color-light;
    margin-top: 32px;
    filter: brightness(125%);
    padding: 8px 16px;
    border-left: 4px solid $color-wasteland-4;
    box-shadow: 0px 4px 20px 0px $color-dark;

    .link {
      text-decoration: underline;
      color: $color-light;
      padding: 0px 4px;
    }
  }
}

.pointyCursor {
  cursor: pointer;
}
