@import '../../theme.scss';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  .wrapper {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .home {
      display: inline-flex;
      font-family: Bellefair, serif;
      color: $color-light;
      width: 128px;
      text-align: center
    }

    .base {
      width: 100%;
      background-color: $color-dark;
      border-radius: 16px;

      &.half {
        width: calc(50% - 8px);
      }

      .select {
        color: $color-light;
        font-family: Bellefair, serif;
        padding: 8px 16px;
      }

      .icon {
        color: $color-light;
        margin-right: 8px;
      }

      &.disabled {
        :global(svg) {
          font-size: 18px;
          margin-top: 2px;
          margin-right: 10px;
        }
      }
    }
  }
}

.menu {
  opacity: 0.9;

  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    margin-top: 88px;
    margin-left: 0px;
    border-radius: 16px;
  }

  &.pushUp {
    :global(.MuiMenu-paper) {
      margin-top: 56px;
    }
  }

  .option {
    font-family: Bellefair, serif;
    color: $color-light;
    width: 160px;

    .checkbox {
      margin-right: 8px;
      margin-bottom: -6px;
    }
  }

  .singleOption {
    font-family: Bellefair, serif;
    padding: 4px 16px;
    width: 160px;
    color: $color-light;
    cursor: pointer;

    &:hover {
      color: $color-light;
    }

    &.wide {
      width: 224px;
    }

    &.full {
      width: calc(100%);
    }
  }
}
