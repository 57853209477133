@import '../../theme.scss';

.container {
	margin: 16px 0px 8px 0px;

	&:hover {
		.options {
			display: block;

			&.forcedBlur {
				display: none;
			}
		}
	}

	.beacon {
		display: flex;
		justify-content: space-between;

		background-color: $color-dark;
		color: $color-light;
		border-radius: 32px;
		padding: 8px 16px;

		.text {
			flex: 1 1 100%;
		}

		.icon {
			flex: 0 0 24px;
			font-size: 20px;
			margin-left: -4px;
			margin-right: 8px;
		}
	}

	.options {
		display: none;
		position: absolute;

		margin: 8px;
		margin-top: -32px;
		background-color: $color-off-dark;
		box-shadow: 0px 0px 20px 0px $color-dark;
		padding: 16px 0px;
		border-radius: 8px;
		opacity: 0.9;
		max-height: 50vh;
		overflow-y: auto;

		.option {
			padding: 8px 16px;
			cursor: pointer;

			&.selected {
				background-color: $color-light;
				color: $color-dark;
				pointer-events: none;
				cursor: default;
			}

			&:hover {
				background-color: $color-light;
				color: $color-dark;
			}

			&.emptyOption {
				color: $color-midtone;

				&:hover {
					background-color: $color-off-dark;
					color: $color-midtone;
				}
			}
		}
	}
}
