@import '../../theme.scss';

.container {
  position: absolute;
  z-index: 9999;
  right: 16px;
  top: 16px;
  color: $color-light;
  border-radius: 16px;
  border-bottom-right-radius: 0;
  box-shadow: 0px 20px 20px 0px $color-dark;
  background-color: $color-dark;
  opacity: 0.9;

  .title {
    background-color: $color-light;
    color: $color-dark;
    text-align: left;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 8px 8px 6px 12px;
    font-size: 16px;
    z-index: 1;
  }

  .close {
    margin-top: -24px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    z-index: 2;
    color: $color-dark;
    text-align: right;
  }

  .content {
    margin-top: 8px;
    padding: 8px;
    max-height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  :global(.flexible-modal-mask) {
    position: fixed;
    height: 100%;
    background-color: $color-dark;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  :global(.flexible-modal-resizer) {
    position:absolute;
    right:0;
    bottom:0;
    cursor:se-resize;
    margin:0px 8px 4px 0px;
    border-bottom: solid 2px $color-midtone;
    border-right: solid 2px $color-midtone;
  }
  :global(.flexible-modal-drag-area) {
    height: 32px;
    right: 32px;
    top:0;
    position:absolute;
    cursor:move;
    width: calc(100% - 32px) !important;
  }
  :global(.flexible-modal-drag-area-left) {
    display: none;
  }
  :global(.flexible-modal-drag-area-right) {
    display: none;
  }
}
