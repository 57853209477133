@import '../../theme.scss';

@mixin collapsible {
  transition: all 300ms ease-in-out;
  overflow-y: hidden;

  &.entering, &.entered {
    max-height: 1000000vh;
    opacity: 1;

    .mods {
      .mod {
        &.notEligible {
          opacity: 0.5;
        }
      }
    }
  }

  &.exiting, &.exited {
    max-height: 0px;
    opacity: 0;
  }
}

.container {
  user-select: text;

  .header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid $color-light;
    font-size: 22px;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;

    .globalLimit {
      font-size: 16px;
      margin-left: 8px;
      margin-bottom: 2px;

      .over {
        color: $color-error;
      }
    }
  }

  .description {
    max-width: 480px;
    margin: 0 auto;
  }

  .scheduler {
    background-color: $color-error-light;
    color: $color-error;
    padding: 4px;
    border-radius: 8px;
    margin: 8px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    margin: 16px auto;

    .icon {
      flex: 0 0 74px;
      font-size: 24px;
      text-align: center;
      margin-bottom: -2px;
      margin-top: 4px;
    }

    .text {
      flex: 1 1 100%;
      text-align: left;
      padding-left: 4px;
    }
  }

  .modContent {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .modSection {
      margin-top: 16px;
      width: 480px;


      .modSectionHeader {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        border-bottom: 1px solid $color-light;

        .expander {
          flex: 0 0 32px;
          cursor: pointer;
        }
        .modSectionTitle {
          flex: 1 1 100%;
          font-size: 24px;
        }

        .modSectionLimit {
          justify-self: flex-end;
          margin-bottom: 4px;
          font-size: 16px;
        }
      }

      .inverseWrapper {
        @include collapsible();
        .collapsedNote {
          background-color: $color-light;
          color: $color-dark;
          padding: 8px;
          border-radius: 16px;
          margin-top: 8px;
          text-align: center;
          cursor: pointer;

          &:hover {
            filter: brightness(125%);
          }
        }
      }

      .wrapper {
        @include collapsible();
        .mods {
          .mod {
            margin-top: 4px;
            display: flex;
            align-items: center;
            border: 1px solid $color-midtone;
            border-radius: 8px;
            max-width: calc(100% - 8px);

            &:hover {
              border: 1px solid $color-light;
            }

            &.notEligible {
              opacity: 0.5;
            }

            .left {
              flex: 1 0 80px;
              text-align: center;
              margin: 8px 0px;

              .waitlist {
                display: inline;
                background-color: $color-error-light;
                color: $color-error;
                padding: 0px 4px;
                border-radius: 4px;
              }

              .seated {
                display: inline;
                background-color: $color-anomaly-4;
                padding: 0px 4px;
                border-radius: 4px;
                filter: brightness(150%);
              }

              .overLimit {
                color: $color-error;
              }
            }

            .right {
              flex: 1 1 100%;
              max-width: calc(100vw - 96px);

              .modInfo {
                .modName {
                  text-decoration: underline;
                  font-size: 18px;
                  margin: 4px 0;
                }

                .modConstraint {
                  .constraint {
                    padding: 0px 8px 0px 4px;
                    border-radius: 4px;

                    &.wasteland {
                      background-color: $color-wasteland-4;
                      border-left: 8px solid $color-wasteland-0;
                    }

                    &.civilized {
                      background-color: $color-civilized-4;
                      border-left: 8px solid $color-civilized-0;
                    }

                    &.anomaly {
                      background-color: $color-anomaly-4;
                      border-left: 8px solid $color-anomaly-0;
                    }

                    &.combat {
                      background-color: $color-combat-4;
                      border-left: 8px solid $color-combat-0;
                    }
                  }

                  .eligibleCharacters {
                    &.notEligible {
                      font-style: italic;
                    }
                  }
                }

                .modDescription {
                  border-top: 1px solid $color-midtone;
                  margin-top: 4px;
                  padding-top: 4px;
                  padding-bottom: 4px;
                }

                .modParticipants {
                  .participants {
                    display: flex;
                    border-top: 1px solid $color-midtone;
                    white-space: nowrap;
                    overflow-x: hidden;

                    .title {
                      text-align: center;
                      padding: 4px 0px;
                      border-bottom: 1px solid $color-midtone;
                    }

                    .content {
                      padding: 4px;

                      .row {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow-x: hidden;
                        max-width: 100%;

                        &:hover {
                          .floating {
                            display: block;
                          }
                        }

                        &.self {
                          color: $color-anomaly-3;
                          filter: brightness(250%);
                        }
                      }
                    }

                    .seated {
                      flex: 0 0 50%;
                      max-width: 50%;
                      border-right: 1px solid $color-midtone;
                    }

                    .waitlisted {
                      flex: 0 0 50%;
                      max-width: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.floating {
  display: none;
  position: absolute;
  background-color: $color-light;
  color: $color-dark;
  margin-top: -18px;
  padding: 0px 4px;
  border-radius: 4px;
}
