@import '../../theme.scss';

.container {
	margin-top: 8px;

	.transactionsFilter {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 8px;

		.inputFilter {
			border: 0;
			background-color: $color-dark;
			padding: 8px 16px;
			border-radius: 32px;
			font-family: Bellefair, serif;
			font-size: 16px;
			color: $color-light;
			width: 160px;
			margin: 8px 0px;
		}

		.wildcardFilter, .idFilter, .playerIdFilter {
			display: flex;
			margin: 0 auto;
		}

		.clear {
			margin-top: 16px;
			margin-left: -24px;
			margin-right: 16px;
			cursor: pointer;
		}
	}

	.recency {
		padding: 8px 0px;

		.clickable {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.transactionsTable {
	}
}
