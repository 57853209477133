@import '../theme.scss';

.container {
  margin-top: 64px;

  .header {
    padding: 0px 0px 0px 8px;
  }

  .content {
    margin-top: 16px;
  }
}
