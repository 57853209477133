@import '../../theme.scss';

.statgrid {
  width: 25%;
  text-align: center;
  border-right: 1px solid $color-off-dark;
  padding: 8px 8px;
  background-color: $color-dark;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  height: 16px;
  &.overlayActive {
    background-color: $color-light;
    color: $color-dark;

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }
  }
  &:hover {
    background-color: $color-midtone;
  }

  &:first-of-type {
    border-right: 1px solid $color-off-dark;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 12px;

    &.overlayActive {
      border-bottom-left-radius: 0px;
    }
  }

  &:last-of-type {
    border-right: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 12px;

    &.overlayActive {
      border-bottom-right-radius: 0px;
    }
  }
}

.icon {
  font-size: 16px;

  &.body {
    padding-left: 1px;
  }

  &.mind {
    padding-left: 4px;
  }

  &.infection {
    padding-left: 1px;
  }
}

.upshift {
  margin-top: -2px;
}
