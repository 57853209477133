@import '../theme.scss';

.container {
  .title {
    text-align: center;
    font-size: 20px;
    margin: 8px 0px;
  }

  .chart {
    height: 224px;
    max-width: 640px;
    padding: 0px 16px;
    margin: 0 auto;

    :global(text) {
      fill: $color-light;
    }

    :global(rect) {
      &:hover {
        stroke-width: 1;
      }
    }

    .hoverable {
      overflow: visible;

      :global(path), :global(circle) {
        cursor: pointer;
      }

      &:hover {
        :global(path), :global(circle) {
          stroke-width: 2.5;
        }
      }
    }
  }
}

.divider {
  border: 0;
  border-top: 1px solid $color-light;
}

.tooltip {
  color: #fff !important;
  .listGroup {
    margin: 4px 0px;
    padding-left: 24px;
  }
}
