@import '../theme.scss';

.container {
  padding: 0px 4px;
  max-width: 640px;
  width: calc(100vw - 256px);
  min-width: 464px;
  margin: 0 auto;
  margin-bottom: 64px;

  .subSection {
    margin-bottom: 16px;
    .header {
      font-size: 24px;
      border-bottom: 1px solid $color-light;
    }

    .content {
      .row {
        display: flex;
        margin: 2px 0px;

        .timestamp {
          flex: 0 0 15%;
          text-align: right;
        }

        .playerID {
          flex: 0 0 9%;
          padding-left: 8px;
          text-align: right;

          .link {
            color: $color-light;
            text-decoration: underline;
          }
        }

        .playerName {
          flex: 0 0 38%;
          padding-left: 8px;
        }

        .branch {
          flex: 0 0 38%;
          padding-left: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);
  }
}
