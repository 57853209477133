@import '../../theme.scss';

.checkin {
  background-color: $color-light;
  color: $color-dark;
  padding: 8px 16px;
  border-radius: 16px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 64px;
  cursor: pointer;

  &.disabled {
    background-color: $color-midtone;
    color: $color-dark;
    cursor: not-allowed;
  }
}
