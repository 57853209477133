@import '../../theme.scss';

.container {
  color: $color-light;
  font-family: Bellefair, serif;
  min-width: 464px;
  width: calc(100vw - 240px);

  margin: 0 auto;
  margin-top: 64px;
  padding: 0px;
  overflow-x: hidden;

  .eventHeader {
    margin: 0 auto;
    max-width: 480px;
  }

  .checkinContent {
    /*max-width: 480px;*/
    max-width: calc(100vw - 240px);
    margin: 0 auto;

    .beNice {
      width: calc(100vw - 36px);
      max-width: 480px;
      margin: 0 auto;
      background-color: $color-light;
      color: $color-dark;
      padding: 8px;
      padding-bottom: 16px;
      border-radius: 8px;

      .big {
        font-size: 22px;
        border-bottom: 1px solid $color-dark;
        display: flex;
        align-items: center;
        padding: 8px 0;
        margin-bottom: 16px;

        .exclamationIcon {
          padding: 8px;
        }
      }
    }

    .notCheckedIn {
      width: calc(100vw - 240px);
      max-width: 480px;
      margin: 0 auto;
    }

    .checkedIn {
      width: calc(100vw - 240px);
      max-width: 480px;
      margin: 0 auto;

      .thanks {
        font-size: 24px;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 16px;
        border-bottom: 1px solid $color-light;

        .link {
          color: $color-light;
          margin-left: 16px
        }
      }

      .subheader {
        font-size: 24px;
      }

      .list {
        display: flex;
        justify-content: flex-start;
        font-size: 20px;
        flex-wrap: wrap;

        .cost, .shiftKind, .blank {
          padding: 0 8px;
          text-align: right;
          width: 25%;
        }

        .label {
          width: 50%;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.registrationLink {
  color: $color-dark;

  .icon {
    font-size: 16px;
    padding: 0px 2px;
  }
}

@media (max-width: 739px) {
  .container {
    width: calc(100vw - 8px);
    min-width: unset;

    .eventHeader {
      width: calc(100vw - 8px);
      min-width: unset;
    }

    .checkinContent {
      width: calc(100vw - 8px);
      min-width: unset;
      max-width: unset;

      .notCheckedIn {
        max-width: 480px;
        width: calc(100vw - 8px);
      }

      .checkedIn {
        max-width: 480px;
        width: calc(100vw - 8px);
      }
    }
  }
}
