@import '../../theme.scss';

.container {
  margin-top: 8px;

  .blurb {
    .retype {
      font-family: monospace;
      font-size: 14px;
      background-color: $color-light;
      color: $color-dark;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0 4px;
    }
  }

  .confirmation {
    margin-top: 8px;

    .input {
      width: 100%;
      background-color: $color-dark;
      border: 1px solid transparent;
      border-radius: 16px;
      color: $color-light;
      padding: 0px 16px;
      font-family: Bellefair, serif;

      &:focus {
        border: 1px solid $color-light
      }
    }
  }

  .buttonRow {
    width: 100%; 
    margin-top: 8px;   

    .button {
      border-radius: 16px;
      padding: 8px 0px;
      font-family: Bellefair, serif;
      font-size: 16px;
      color: $color-dark;
      border: 0;
      cursor: pointer;
      background-color: $color-light;
      width: 100%;

      &:hover {
        filter: brightness(125%);
      }

      &.disabled {
        background-color: $color-midtone;
        cursor: not-allowed;
      }
    }
  }
}

.futureRender {
  width: 100%;

  .futureEvents {
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;

    .futureEvent {
      width: calc(100% - 16px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .text {
        color: $color-light;
      }
    }
  }
}
