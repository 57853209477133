@import '../theme.scss';

.container {
  padding: 0px 4px;
  max-width: 640px;
  width: calc(100vw - 256px);
  min-width: 464px;
  margin: 0 auto;
  margin-bottom: 64px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);
  }
}
