@import '../../theme.scss';

.base {
  width: 80vw;
  max-width: 480px;
  background-color: $color-dark;
  color: $color-light;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 80px;
  outline: 0;
  border-radius: 16px;
  flex-wrap: wrap;

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 16px 12px 16px;
    background-color: $color-light;
    color: $color-dark;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .title {
      font-family: Bellefair, serif;
      font-size: 20px;
    }

    .close {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .body {
    width: 100%;
    padding: 16px;
    font-family: Bellefair, serif;
    font-size: 16px;
  }

  .footer {
    width: 100%;
    padding: 16px;
    border-top: 1px solid $color-midtone;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .errorMessage {
      width: 100%;
      background-color: $color-error-light;
      color: $color-error;
      font-family: Bellefair, serif;
      padding: 8px 16px;
      border-radius: 16px;
      margin-bottom: 8px;

      &.hidden {
        display: none;
      }
    }
  }
}

.button {
  font-family: Bellefair, serif;
  font-size: 16px;
  padding: 4px 16px;
  background-color: $color-light;
  color: $color-dark;
  border-radius: 16px;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(125%);
  }

  &.cancel {
    background-color: $color-dark;
    border: 1px solid $color-midtone;
    color: $color-light;

  }

  &.modified {
    background-color: $color-light;
    color: $color-dark;
  }

  &.done {
    background-color: $color-wasteland-3;
    color: $color-light;
    border-color: $color-midtone;
    cursor: not-allowed;

    &:hover {
      filter: none;
    }
  }

  &.error {
    background-color: $color-error-light;
    color: $color-error;
    border-color: $color-error-light;

    &:hover {
      border-color: $color-error;
      filter: none;
    }
  }

  &.disabled {
    background-color: $color-midtone;
    border-color: $color-midtone;
    cursor: not-allowed;

    
  }
}

.break {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.success {
  font-family: Bellefair, serif;
  background-color: $color-success-light;
  color: $color-light;
  text-align: left;
  width: 100%;
  padding: 8px 16px;
  border-radius: 16px;
  margin-top: 16px;
}

.membership {
  font-family: Bellefair, serif;
  margin-top: 16px;
  text-align: right;

  .link {
    text-decoration: underline;
  }
}

.membershipHistory {
  width: 100%;
  font-family: Bellefair, serif;
  margin-top: 8px;

  .title {
    font-size: 20px;
    border-bottom: 1px solid $color-midtone;
    margin-bottom: 8px;
  }

  .content {
    

    .row {
      color: $color-light;
      display: flex;
      font-size: 16px;
      font-family: monospace, serif;

      .marker {
        flex: 0 0 32px;
        font-size: 24px;
        margin-top: -8px;
        margin-bottom: -8px;
        margin-right: 8px;
        text-align: right;
      }

      .date {
      }

      &.expired {
        color: $color-midtone;
      }
    }
  }
}

@media (max-width: 480px) {
  .base {
    width: 90vw;
  }
}
