// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=c4K2I0k3j1klsk3e4n+hWlWq4hftUfqkdI8Rl57hhD8e96ad8WfwkqqfPlejDnxhVsXehv0d2kPmmQXksUThqyMmj5Jvg3 */

$color-primary-0: #56266a; // Main Primary color */
$color-primary-1: #6f4a7f;
$color-primary-2: #613674;
$color-primary-3: #46145b;
$color-primary-4: #3c0952;

$color-secondary-1-0: #7e3a16; // Main Secondary color (1) */
$color-secondary-1-1: #9c5935;
$color-secondary-1-2: #8f4a26;
$color-secondary-1-3: #722f0b;
$color-secondary-1-4: #682603;

$color-secondary-2-0: #00696e; // Main Secondary color (2) */
$color-secondary-2-1: #00858b;
$color-secondary-2-2: #007a80;
$color-secondary-2-3: #00585c;
$color-secondary-2-4: #004a4d;

$color-complement-0: #293c22; // Main Complement color */
$color-complement-1: #1a3111;
$color-complement-2: #213819;
$color-complement-3: #374532;
$color-complement-4: #546a4c;

// Palette URL: http://paletton.com/#uid=1560u0kPDnIKjt-NcqxRLkRTKhO */

$color-quint-0: #92005f; // Main Primary color */
$color-quint-1: #b60076;
$color-quint-2: #a3006a;
$color-quint-3: #800053;
$color-quint-4: #6e0047;

/////////////////////////////////////////////////////////////////

$color-combat-0: $color-secondary-1-0; // Main combat color */
$color-combat-1: $color-secondary-1-4;
$color-combat-2: $color-secondary-1-3;
$color-combat-3: $color-secondary-1-0;
$color-combat-4: $color-secondary-1-2;

$color-wasteland-0: $color-complement-0; // Main Secondary color (1) */
$color-wasteland-1: $color-complement-1;
$color-wasteland-2: $color-complement-2;
$color-wasteland-3: $color-complement-3;
$color-wasteland-4: $color-complement-4;

$color-anomaly-0: $color-secondary-2-1; // Main Secondary color (2) */
$color-anomaly-1: $color-secondary-2-4;
$color-anomaly-2: $color-secondary-2-3;
$color-anomaly-3: $color-secondary-2-0;
$color-anomaly-4: $color-secondary-2-2;

$color-civilized-0: $color-primary-0; // Main civilized color */
$color-civilized-1: $color-primary-4;
$color-civilized-2: $color-primary-3;
$color-civilized-3: $color-primary-2;
$color-civilized-4: $color-primary-1;

$color-community-0: $color-quint-4;
$color-community-4: $color-quint-0;

// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */
$color-unused-4: #525252;
$color-unused-3: #626262;
$color-unused-2: #727272;
$color-unused-1: #828282;

$color-dark: #333;
$color-off-dark: #3f3f3f;
$color-midtone: #777;
$color-light: #ccc;
$color-gray: #888;
$color-error: #F60;
$color-error-light: #FCA;
$color-success-light: $color-complement-4;

// FONTS ////////////////////////////////////////////////////

// FONT-FACE MIXIN

// String Replace Function

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// Font Face Mixin

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
