@import '../../theme.scss';

.container {
  .obsolete {
    background-color: $color-error-light;
    color: $color-error;
    border: 1px solid $color-error;
    padding: 8px 16px;
    margin: 16px 0px 8px 0px;

    .link {
      text-decoration: underline;
      cursor: pointer;
      margin: 0px 4px;
      color: $color-error;
    }
  }

  .row {
    display: flex;
    align-items: center;

    .persona {
      flex: 1 1 34%;
      margin-right: 8px;

      .guideIcon {
        font-size: 16px;
        margin-left: 4px;
        margin-bottom: -2px;
      }

      .characters {
        border-top: 1px solid $color-midtone;
        margin-top: 4px;
        padding-top: 4px;
      }
    }

    .logs {
      flex: 1 1 66%;
    }
  }
}
