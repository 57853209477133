@import '../../theme.scss';

.container {
  margin-top: 8px;
  margin-bottom: 32px;

  .log {
    .eventHeader {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin-top: 16px;

      .eventDate {
        text-align: right;
        font-size: 16px;
      }
    }

    .readOnlyLogs {
      border-top: 1px solid $color-midtone;
      margin-top: 4px;
      padding-top: 4px;

      .noLogs {
        color: $color-midtone;
        font-style: italic;
      }
    }
  }
}
