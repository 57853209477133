@import '../theme.scss';

.container {
	display: flex;

	.textBox {
		width: calc(100% - 48px);
		margin: 8px auto;
		border: 0px;
		border-radius: 16px;
		background-color: $color-dark;
		color: $color-light;
		padding: 8px 16px;
		font-family: Bellefair, serif;
		font-size: 16px;
	}

	.icon {
		margin-left: -38px;
		margin-right: 12px;
		margin-top: 12px;
		cursor: pointer;
	}
}
