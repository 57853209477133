@import '../../theme.scss';

.container {
  border-top: 1px solid $color-midtone;
  margin-top: 16px;
  padding-top: 8px;

  .title {
    text-align: center;

    .bold {
      text-decoration: underline;
    }
  }

  .control {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .button {
    background-color: $color-light;
    color: $color-dark;
    border-radius: 16px;
    padding: 8px;
    margin-top: 8px;
    text-align: center;
    cursor: pointer;

    &:hover {
      filter: brightness(125%);
    }

    &.disabled {
      background-color: $color-midtone;
      cursor: not-allowed;

      filter: none;
    }
  }
}
