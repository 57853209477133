@import '../../theme.scss';

@mixin awarded() {
  background-color: $color-civilized-3;
  color: $color-light;
  filter: brightness(125%) saturate(75%);
}

@mixin approved() {
  background-color: $color-wasteland-0;
  color: $color-light;
  filter: brightness(150%) saturate(75%);
}

.cell {
  background-color: $color-light;
  color: $color-dark;
  border-radius: 16px;
  padding: 6px 16px;
  width: auto;
  display: inline-flex;
  margin-bottom: 4px;
  margin-top: 4px;
  margin-right: 8px;
  cursor: pointer;

  &.overlayActive {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.past {
    background-color: $color-midtone;
  }

  &.approved {
    @include approved();
  }

  &.awarded {
    @include awarded();
  }
}

.icon {
  margin-bottom: -4px;
  margin-top: -4px;
  margin-right: -4px;

  &.downshift {
    margin-top: -3px;
    margin-right: -8px;
  }
}

.iconIrl {
  margin-right: 4px;
}

.overlay {
  background-color: $color-dark;
  color: $color-light;
  padding: 8px 0px;
  border-radius: 16px;
  text-align: center;
  min-width: 128px;
  margin-top: -30px;
  font-family: Bellefair, serif;
  border: 1px solid $color-midtone;
  z-index: 5;

  &.approved {
    @include approved();
  }

  &.awarded {
    @include awarded();
  }

  .mask {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    margin-top: -3px;

    margin-bottom: 4px;
    border-bottom: 1px solid $color-midtone;
    cursor: pointer;
  }  

  .text {
    padding: 4px 16px;
    
    &.link {
      text-decoration: underline;
      cursor: pointer;
    }

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }

    &.muted {
      color: $color-midtone;
      text-decoration: none;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        color: $color-midtone;
      }
    }
  }

  .disabledCheckin {
    color: $color-midtone;
    max-width: 112px;
    margin: 0 auto;
    padding: 4px 0px;
  }
}

.printLink {
  color: $color-light;
}
