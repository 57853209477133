@import '../../../../theme.scss';

.equipmentName {
  text-decoration: underline;
}

.equipContainer {
  margin-bottom: 8px;
}

.equipmentType {
  text-transform: lowercase;
}

.tabs {
  display: flex;
  justify-content: space-around;
  background-color: $color-midtone;

  .tab {
    padding: 7px;
    outline: none;
    border: 0px;
    margin: 0;
    cursor: pointer;
    font-family: Bellefair, serif;
    font-size: 14px;
    flex: 1 1 25%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:first-of-type {
      border-top-left-radius: 0px;
    }

    &:last-of-type {
      border-top-right-radius: 0px;
    }
  }
}

.tabViewContainer {
  width: 400%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.tabView {
  color: $color-dark;
  padding: 10px;
  will-change: transform;
  width: calc(25% - 20px);
  max-height: 135px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.resolveLocked {
  color: $color-error;
  font-style: italic;
  margin-bottom: 8px;
  filter: brightness(0.75);
}
