@import '../../theme.scss';

.container {
  margin-bottom: 8px;

  .header {
    font-size: 20px;
    border-bottom: 1px solid $color-light;
    margin-bottom: 4px;
  }

  .description {
    margin-bottom: 4px;
  }
  
  .managerList {
    margin-bottom: 8px;

    .row {
      display: flex;
      justify-content: space-between;
      padding: 4px;

      .managerText {

      }

      .remove {
        margin-left: 16px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .inputContainer {
    width: 100%;
    display: flex;

    .box {
      border-radius: 16px;
      background-color: $color-dark;
      color: $color-light;
      //width: 100%;
      border: 0;
      padding: 8px 16px;
      font-family: Bellefair, serif;
      font-size: 14px;
      width: 100%;
    }

    .buttonAdd {
      flex: 0 0 64px;
      width: 100%;
      border-radius: 16px;
      font-family: Bellefair, serif;
      font-size: 14px;
      background-color: $color-light;
      margin-left: 8px;
      border: 0;

      &:hover {
        filter: brightness;
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: $color-midtone;

        &:hover {
          filter: none;
        }
      }
    }
  }
}
