@import '../../theme.scss';

.container {
  max-width: 480px;
  margin: 0 auto;
  margin-top: 80px;

  .title {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .subTitle {
    border-bottom: 1px solid $color-midtone;
    margin-bottom: 8px;
    font-size: 20px;
  }

  .contacts {
    .row {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 8px;

      .name {
        flex: 0 1 40%;
      }
    }
  }
}
