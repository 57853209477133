@import '../../../../theme.scss';

@mixin tabbed($color) {
  border-left: 4px solid $color;
}

.skill-info-container {
  max-width: 480px;
  margin: 0 auto;

  .skill-info {
    font-family: 'Bellefair', serif;
    margin-left: 15px;
    margin-right: 2px;
    margin-top: 0px;
    z-index: 25;
    position: relative;
    width: calc(100vw - 36px);
    max-width: 462px;

    &.hidden {
      display: none;
    }

    .skill-tab-container {
      background-color: $color-light;
      overflow-x: hidden;

      &.combat {
        @include tabbed($color-combat-4);
      }

      &.civilized {
        @include tabbed($color-civilized-4);
      }

      &.wasteland {
        @include tabbed($color-wasteland-4);
      }

      &.anomaly {
        @include tabbed($color-anomaly-4);
      }

      &.community {
        @include tabbed($color-community-4);
      }
    }
  }
}
