@import '../theme.scss';

.container {
  margin-bottom: 16px;

  .header {
    border-bottom: 1px solid $color-light;
    font-size: 24px;

    display: flex;
    justify-content: space-between;

    cursor: pointer;

    .text {
      .clickToFetch {
        font-size: 16px;
        margin-left: 16px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .content {
    &.hidden {
      display: none;
    }

    .travelerGroup {
      .branchHeader {
        text-align: right;
        background-color: $color-off-dark;
        border-top: 1px solid $color-midtone;
        border-bottom: 1px solid $color-midtone;
        color: $color-light;
        font-size: 18px;
        padding: 8px;
      }
    }

    .row {
      display: flex;
      align-items: center;
      margin: 2px 0px;

      .playerID {
        flex: 0 0 48px;
        text-align: right;
        margin-right: 8px;

        .link {
          cursor: pointer;
          text-decoration: underline;
          color: $color-light;
        }
      }

      .playerName {
        flex: 1 1 33%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .punchCard {
        flex: 1 1 50%;
      }

      .flag {
        font-size: 14px;
        opacity: 0.2;
        margin-right: 4px;
        cursor: pointer;

        &.withFlags {
          opacity: 1;
        }
      }
    }
  }
}
