@import '../theme.scss';

.incompatible {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  width: 100%
  // text-align: center
}

.builder {
  display: flex;
  // max-width: 480px;
  margin: 28px auto 0 auto;
  background-color: $color-off-dark;
  padding: 16px 0px 0px 0px;
  flex-wrap: wrap;
  overflow-x: hidden;

  .container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0px 8px;

    .control {
      max-width: 480px;
      //width: calc(100% - 16px);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      //position: fixed;
      margin: 0 auto;
      left: 50%;
      //transform: translateX(-50%);
      background-color: $color-off-dark;
      z-index: 7;
      padding-top: 12px;
      padding-bottom: 4px;
      transition: all 700ms;

      &.entering,
      &.entered {
        margin-top: 0;
      }

      &.exiting,
      &.exited {
        margin-top: -128px;
        margin-bottom: 128px;
      }
    }

    .megaGroup {
      transition: all 700ms ease-in-out;

      &.entering,
      &.entered {
        opacity: 1;
        transform: scale(1);
      }

      &.exiting,
      &.exited {
        opacity: 0;
        transform: scale(0.25);
      }
    }
  }
}

.userEditor {
  position: absolute;
  top: 24px;
  z-index: 50;
  transition: all 300ms ease-in-out;

  &.entering,
  &.entered {
    left: 0;
  }

  &.exiting,
  &.exited {
    left: 100vw;
  }
}

.belowControl {
  z-index: 6;
}

.divider {
  margin: 16px auto;
  border: 1px dashed $color-midtone;
  width: 100%;
  max-width: 480px;
}

.noHorizontalScroll {
  overflow-x: hidden !important;
}
