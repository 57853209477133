@import '../../theme.scss';

.xpbar-overlay {
  background-color: $color-light;
  width: calc(100% - 48px);
  max-width: calc(480px - 32px);
  padding: 16px;
  padding-top: 34px;
  border: 0;
  margin: 0px 0 0 0;
  border-radius: 16px;
  color: $color-dark;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  z-index: 25;
  opacity: 0.9;

  margin-top: -34px;
  background: linear-gradient(
    180deg,
    rgba(255,255,255,0.25) 34px,
    $color-light 35px,
  );
}
