@import '../../theme.scss';

.container {
  width: calc(100vw - 240px);
  max-width: 480px;
  margin: 0 auto;

  .searchBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputField {
      width: 100%;
    }

    .button {
      padding: 6px 16px 4px 16px;
      background-color: $color-light;
      height: 20px;
      color: $color-dark;
      text-align: center;
      border-radius: 16px;
      margin: 0 0 -3px 16px;
      cursor: pointer;

      &:hover {
        filter: brightness(125%);
      }
    }
  }

  .impersonatee {
    color: $color-light;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 22px;
    text-align: center;
    border-bottom: 1px solid $color-light;
  }
}

@media (max-width: 739px) {
  .container {
    max-width: 480px;
    width: calc(100vw - 8px);
  }
}
