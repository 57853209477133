@import '../theme.scss';

.playerMetadata {
  font-family: Bellefair, serif;
  color: $color-light;
  background-color: $color-off-dark;
  max-width: 240px;
  padding: 8px 8px 8px 16px;
  user-select: text;

  .playerID {
    font-weight: bolder;
    font-size: 16px;
    display: flex;
    justify-content: space-between;

    .edit {
      cursor: pointer;
      margin-bottom: -16px;
      margin-right: 8px;
    }
  }

  .playerName {
    .icon {
      margin-bottom: -4px;
      margin-left: 4px;
    }
  }

  &.impersonating {
    background-color: $color-light;
    color: $color-dark;
  }

  &.error {
    background-color: $color-error-light;

    .playerID {
      color: $color-error;
      font-weight: normal;
    }
  }
}
