@import '../../theme.scss';

.container {
  padding: 16px 0;
  border-left: 4px solid $color-light;

  .control {
    display: flex;
    justify-content: center;
    align-items: center;

    .dispenseAmount {
      font-family: Bellefair, serif;
      color: $color-light;
      background-color: $color-dark;
      border: 1px solid transparent;
      text-align: center;
      border-radius: 32px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 8px 16px;
      font-size: 16px;
      outline: none;
      margin-right: 4px;

      &:focus, &:hover {
        border-color: $color-light;
      }
    }

    .commit {
      font-family: Bellefair, serif;
      color: $color-dark;
      background-color: $color-light;
      border: 0;
      cursor: pointer;
      border-radius: 32px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 8px 16px;
      font-size: 16px;
      

      &.disabled {
        color: $color-midtone;
        background-color: $color-dark;
        cursor: not-allowed;
      }
    }
  }

  .help {
    text-align: center;
    padding-top: 8px;
  }
}