@import '../../../theme.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
  margin-bottom: 8px;

  input {
    border-radius: 32px;
    padding: 8px 16px;
    border: 1px solid transparent;
    font-family: Bellefair, serif;
    background-color: $color-dark;
    color: $color-light;
    outline: none;

    &:focus, &:hover {
      border-color: $color-light;
    }
  }

  .left {
    flex: 1 1 100%;

    &.plain {
      margin-left: 16px;
    }
  }


  .status {
    flex: 0 0 80px;
  }

  button.right {
    flex: 0 0 96px;
    margin-left: 8px;
    border-radius: 32px;
    font-family: Bellefair, serif;
    border: 0;
    padding: 8px;

    &.disabled {
      cursor: not-allowed;
      background-color: $color-midtone;
    }
  }
}

.active {
  color: $color-light;
}

.resolved {
  color: $color-midtone;
}