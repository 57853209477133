.new {
  //display: flex;
  //justify-content: space-between;
  font-family: Bellefair, serif;
  cursor: pointer;
  border-radius: 16px;
  padding-bottom: 4px;
  margin-top: -2px;
  width: 100%;
  text-align: center;
  padding-right: 16px;

  .row {
    width: 100%;
    padding: 4px 0px;
    display: flex;
    justify-content: center;

    .text {
      margin-top: 2px;  
      margin-bottom: 4px;
    }
    svg {
      margin-top: 0px;
      margin-left: 8px;
    }
  }
}
