@import '../../theme.scss';

.characterSelector {
  display: flex;
  justify-content: flex-end;
  z-index: 51;

  button {
    max-width: 67vw;
    margin: -12px 0 -12px 0px;
    text-transform: none;
    color: $color-light;
    border-radius: 0;
    padding: 16px 12px 4px 12px;
    font-family: Bellefair, serif;
    font-size: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      margin-right: 16px;
    }
  }
}

.dropdown {
  position: absolute;
  right: 0;
  top: 22px;
  background-color: transparent;
  padding-left: 4px;
}

.overlayContainer {
  display: flex;
  justify-content: flex-end;
  z-index: 51;

  .overlay {
    background-color: $color-dark;
    color: $color-light;
    margin: 0;
    padding: 8px 0px 0px 16px;
    width: 100%;
    max-width: 320px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid $color-midtone;
    border-top: 0;
    max-height: 67vh;
    overflow: hidden;
  }

  .button {
    cursor: pointer;
    font-family: Bellefair, serif;
    text-align: center;
    border-radius: 8px;
    background-color: $color-light;
    //border: 1px solid $color-midtone;
    color: $color-dark;
    margin: 0 auto;
    padding: 8px 16px;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 192px;

    .icon {
      margin-bottom: -6px;
      margin-right: 8px;
    }

    &:hover {
      filter: brightness(125%);
    }
  }
}

.noHorizontalScroll {
  overflow-x: hidden !important; // HAXX!!!
}

.divider {
  background-color: $color-midtone;
  margin-bottom: 8px;
  margin-top: 0px;
  height: 1px;
  flex-shrink: 0;
  border: none;
}

.expandHidden {
  display: none !important;
}

.marker {
  .text {
    font-family: Bellefair;
    margin-bottom: -10px;
    padding-right: 8px;
    font-size: 18px;
    margin: 0 auto;
    background-color: $color-dark;
  }

  .divider {
    background-color: $color-midtone;
    margin-bottom: 16px;
    margin-top: -10px;
  }
}

.login {
  cursor: pointer;
  font-family: Bellefair, serif;
  text-align: center;
  border-radius: 8px;
  background-color: $color-midtone;
  color: $color-dark;
  width: 144px;
  margin: 0 auto;
  padding: 8px;
  margin-bottom: 8px;

  .svg {
    margin-bottom: -6px;
    margin-right: 8px;
  }

  &:hover {
    background-color: $color-light;
  }
}

.nada {
  text-align: center;
  font-family: Bellefair, serif;
  margin-bottom: 4px;
}

.upstreamState {
  margin-top: 10px;
  margin-bottom: -10px;
}

.blinking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .characterSelector {
    button {
      max-width: 50vw;
    }
  }
}
