@import '../../theme.scss';

.result {
  &.hidden {
    display: none;
  }

  .wrapper {
    .title {
      background-color: $color-light;
      color: $color-dark;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 8px 16px;
      margin-top: 8px;

      &.zeroResult {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .body {
      max-height: 40vh;
      overflow-y: auto;
      border: 1px solid $color-light;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 4px 0px;

      &.zeroResult {
        display: none;
      }
      
      .row {
        display: flex;
        width: 100%;
        margin: 2px 0px;

        .id {
          cursor: pointer;
          width: 20%;
          text-align: right;
          text-decoration: underline;
          cursor: pointer;
          padding-right: 4px;
        }

        .name {
          width: 80%;

          .muted {
            color: $color-midtone;
          }
        }
      }
    }
  }
}

.message {
  margin-top: 8px;
}
