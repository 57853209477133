@import '../theme.scss';

.wrapper {
  font-family: Bellefair, serif;
  color: $color-light;
  margin-top: 0px;

  .row {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 16px);
    padding-left: 16px;

    .impersonator {
      width: calc(100%);
      
      .input {
        user-select: all;
        z-index: 3;
        font-family: Bellefair, serif;
        color: $color-light;
      }

      &.underline {
        border-bottom: 1px solid $color-midtone;
      }

      &.focused {
        &.underline {
          &:hover {
            &:before {
              border-bottom: 1px solid $color-midtone;
            }
          }

          &:after {
            border-bottom: 2px solid $color-light;
          }
        }
      }
    }

    .help {
      color: $color-midtone;
      cursor: pointer;
      margin-left: -116px;
      margin-top: 2px;
      z-index: 5;

      &:hover {
        color: $color-light;
      }
    }

    .search {
      color: $color-light;
      cursor: pointer;
      margin-left: -32px;
      margin-top: 4px;
      width: 34px;
      z-index: 4;

      &.muted {
        color: $color-midtone;
      }
    }
  }

  .message {
    padding: 4px 8px 4px 16px;

  }

  .result {
    padding: 4px 8px 4px 16px;
    max-height: calc(100vh - 452px);
    min-height: 48px;
    overflow-y: auto;
    box-shadow: 0px 10px 10px -10px $color-midtone;

    .entry {
      display: flex;
      max-width: 240px;

      .id {
        text-align: right;
        text-decoration: underline;
        cursor: pointer;
        width: 20%;
        padding: 2px 8px 2px 0px;
      }

      .name {
        text-align: left;
        width: 80%;
        padding: 2px 0px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 160px;

        .muted {
          color: $color-midtone;
        }

        .icon {
          margin: -4px 0px -4px 4px;
        }
      }
    }
  }
}

.tooltip {
  color: $color-dark !important;
  font-family: Bellefair, serif;
  font-size: 16px;
  max-width: 50vw;
  width: 240px;
  padding: 16px;
  background-color: $color-light !important;

  &:after {
    border-bottom-color: $color-light !important;
  }

  .mono {
    font-family: monospace;
    background-color: #ddd;
    color: $color-dark;
    font-size: 14px;
    margin: 0px 8px;
    padding: 0px 4px;
    border-bottom: 1px solid $color-midtone;
  }
}
