@import '../theme.scss';

.container {
  max-width: 480px;
  padding: 8px;
  margin: 0 auto;
  margin-top: 64px;
  margin-bottom: 64px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid $color-light;
    margin-bottom: 8px;
  }

  .branch {
    margin-bottom: 8px;
    .branchHeader {
      font-size: 20px;
    }

    .noOwner {
      margin-left: 16px;
      color: $color-midtone;
    }

    .staff {
      display: flex;
      max-width: 100%;

      .name {
        flex: 0 0 40%;
        margin-left: 16px;
      }

      .email {
        flex: 0 0 60%;
        user-select: all;
        overflow-wrap: break-word;
        max-width: calc(60% - 16px);
      }
    }
  }
}
