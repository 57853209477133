@import '../theme.scss';

.container {
  margin-top: 8px;

  .title {
    font-size: 20px;
    border-bottom: 1px solid $color-midtone;
    margin-bottom: 8px;
  }

  .content {
    .row {
      display: flex;
      align-items: center;
      margin: 4px 0px;

      .date {
        flex: 0 0 40%;
        text-align: right;
      }

      .description {
        padding-left: 8px;
      }
    }
  }
}
