@import '../../theme.scss';

.wrapper {
  display: flex;
  margin-top: 8px;

  .title {
    width: 40%;
    background-color: $color-light;
    color: $color-dark;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 7px 4px 5px 16px;
    font-size: 16px;

    &.error {
      background-color: $color-error-light;
      color: $color-error;
    }
  }
}

.errorMessage {
  width: 100%;
  font-family: Bellefair, serif;
  color: $color-error;
  margin: 4px 0px;
  padding: 0px 8px 0px 16px;
}

.break {
  flex-basis: 100%;
  height: 0;
}
.input {
  width: 60%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid $color-midtone;
  padding: 6px 16px 6px 8px;
  background-color: $color-dark;
  color: $color-light;
  font-family: Bellefair, serif;
  font-size: 16px;
  outline: none;
  cursor: text;
  z-index: 3;

  &.error {
    border-color: $color-error-light;
  }

  &:hover {
    filter: brightness(125%);
  }

  &.text {
  }
}

.revert {
  margin-left: -32px;
  margin-right: 8px;
  margin-top: 4px;
  font-size: 14px;
  cursor: pointer;
  z-index: 4;

  &:hover {
    filter: brightness(125%);
  }

  &.hidden {
    cursor: default;
    visibility: hidden;
  }
}

@media print {
  .wrapper {
    display: none;
  }
}
