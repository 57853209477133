@import '../../theme.scss';

.statBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $color-dark;
  border-radius: 16px;
  margin: 2px 0px;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  color: $color-light;
}
