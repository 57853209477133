@import '../../theme.scss';

.container {
  padding-top: 24px;

  .section {
    margin-bottom: 16px;

    .header {
      border-bottom: 1px solid $color-light;
      margin-bottom: 8px;
      font-size: 24px;
    }

    .content {

      .subtitle {

      }

      .entryForm {
        display: flex;
        margin: 8px 0px;

        .inputText {
          flex: 0 1 128px;
          background-color: $color-dark;
          border-radius: 16px;
          border: 1px solid $color-dark;
          padding: 6px 16px;
          font-family: Bellefair, serif;
          font-size: 16px;
          margin-right: 16px;
          color: $color-light;

          &:hover {
            border: 1px solid $color-light;
            cursor: pointer;
          }
        }

        .button {
          cursor: pointer;
          border: 0;
          border-radius: 16px;
          background-color: $color-light;
          font-family: Bellefair, serif;
          font-size: 16px;
          padding: 0px 16px;

          &.muted {
            cursor: not-allowed;
            background-color: $color-midtone;
          }
        }
      }

      .list {
        padding-top: 8px;

        .subSection {
          font-size: 20px;
          border-bottom: 1px solid $color-light;
          text-align: right;
          margin-bottom: 8px;
          padding-bottom: 4px;
        }
        .row {
          display: flex;
          justify-content: space-between;
          margin: 4px 0px;

          .id {
            flex: 1 1 32px;
            text-align: right;
            margin-right: 8px;
          }

          .name {
            flex: 1 1 128px;
            white-space: nowrap;
            overflow-x: hidden;
          }

          .right {
            flex: 1 1 64px;
            text-align: right;

            &.link {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
