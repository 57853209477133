.debug {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Bellefair, serif;
  padding-bottom: 16px;
  padding-right: 16px;

  .instructions {
    text-align: center;

    .small {
      font-size: 12px;
    }
  }

  input {
    margin: 4px 16px;
  }
}

svg.MuiSvgIcon-root {
  margin-top: 3px;
  margin-bottom: -3px;
}
