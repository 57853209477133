@import '../../theme.scss';

.container {
  font-family: Bellefair, serif;
  color: $color-light;
  font-size: 20px;
  border-bottom: 1px solid $color-midtone;

  .playerRow {
    display: flex;
    align-items: stretch;

    .cell {
      padding: 0px 8px;

      &.playerID {
        flex: 0 0 10%;
        text-align: right;
        align-self: top;
        font-size: 16px;
        padding-top: 4px;

        .numericID {
          padding-bottom: 1px;
          color: $color-light;
          width: 100%;
          display: block;
        }
      }

      &.playerName {
        flex: 0 0 60%;
        max-width: 60%;

        .humanName {
          padding-top: 4px;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $color-light;

          .misticketed {
            background-color: $color-error-light;
            color: $color-error;
            padding-left: 8px;
            padding-right: 8px;
            border-left: 4px solid $color-error;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          .icon {
            font-size: 16px;
            margin-top: -4px;
            margin-bottom: -4px;
            margin-left: 4px;
          }
        }

        .administrativeInfo {
          height: 32px;

          .ticketType {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          .ticketCancel {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 1px;
            margin-right: 8px;
          }
        }

        .inviterInfo {
          font-size: 16px;
        }
      }

      &.toggles {
        padding: 0;
        flex: 0 0 11%;
        width: 11%;
        align-self: center;
      }
    }
  }

  .characterRow {
    display: flex;
    align-items: stretch;

    .cell {
      padding: 0px 8px;

      &.buffer {
        flex: 0 0 10%;
        background-color: $color-midtone;
      }

      &.characterName {
        flex: 0 0 60%;
        max-width: 60%;
        text-align: right;

        .fantasyName {
          padding-left: 24px;
          padding-top: 4px;
          padding-bottom: 4px;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .staged {
            margin-bottom: -6px;
            margin-right: 4px;
          }
        }

        .administrativeInfo {
          height: 32px;
          padding-left: 24px;

          .ticketCancel {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-left: 0;
            margin-right: 1px;
          }

          .buildGrowth {
            margin-left: 0;
            margin-right: 1px;
          }

          .ticketType {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
          }
        }
      }

      &.toggles {
        padding: 0;
        flex: 0 0 30%;
        align-self: center;
      }
    }
  }
}

.badge {
  background-color: $color-light;
  color: $color-dark;
  font-size: 14px;
  padding: 4px 4px 2px 4px;
  border-radius: 4px;
  display: inline-block;
}

.toggle {
  margin-top: 4px;
}

.ticketType {
  background-color: $color-light;
  color: $color-dark;
  font-size: 14px;
  padding: 4px 4px 2px 4px;
  border-radius: 4px;
}

.buildGrowth {
  &:before {
    content: '+';
  }
}

.buildGrowth, .ticketType {
  font-size: 14px;
  padding: 4px 8px 2px 8px;
  background-color: $color-light;
  color: $color-dark;
  margin-left: 1px;
  cursor: pointer;

  .dropdown {
    font-family: Bellefair, serif;
    font-size: 14px;

    .select {
      background-color: $color-light;
      padding: 0;
      padding-right: 4px;
      margin-right: -4px;
      text-align: center;
      max-width: 320px;

/*      &:hover {
        background-color: $color-light;
      }*/
    }

    .icon {
      display: none;
    }
  }

  &:hover {
    filter: brightness(125%);
  }

  &.noExtraBuild {
    background-color: $color-dark;
    color: $color-midtone;

    .dropdown {
      .select {
        background-color: $color-dark;
        color: $color-midtone;

        &:hover {
          color: $color-light;
        }
      }
    }

    &:hover {
      color: $color-light;
      cursor: pointer;
    }
  }
}

.ticketCancel {
  background-color: $color-light;
  color: $color-dark;
  font-size: 14px;
  padding: 4px 8px 2px 8px;
  border-radius: 4px;
  display: inline-block;

  cursor: pointer;

  &:hover {
    background-color: $color-error-light;
    color: $color-error;
  }
}

.homeBranch {
  background-color: $color-light;
  color: $color-dark;
  font-size: 14px;
  padding: 4px 4px 2px 4px;
  border-radius: 4px;
  display: inline-block;
  //margin-left: 8px;
}

.menu {
  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    border-radius: 16px
  }

  .option {
    font-family: Bellefair, serif;
    color: $color-light;
    width: 56px;
    margin: 0;
    min-height: 16px;
    height: 32px;
    text-align: right;
    justify-content: flex-end;
  }
}

.ticketMenu {
  :global(.MuiMenu-paper) {
    width: 256px;
    background-color: $color-dark;
  }

  .option {
    font-family: Bellefair, serif;
    color: $color-light;
    margin: 0;
    min-height: 16px;
    height: 32px;
    justify-content: flex-start;

    &.currentTicket {
      background-color: $color-light;
      color: $color-dark;

      &:before {
        content: '\000BB';
        font-size: 24px;
        margin-top: -4px;
        margin-right: 4px;
      }
      &:hover {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .playerRow {
      .cell {
        &.playerName {
          flex: 0 1 50%;
          max-width: 50%;
        }

        &.toggles {
          flex: 0 1 15%;
        }
      }
    }

    .characterRow {
      .cell {
        &.characterName {
          flex: 0 1 50%;
          max-width: 50%;
        }

        &.toggles {
          flex: 0 1 15%;
        }
      }
    }
  }

  .ticketType {
    .dropdown {
      .select {
        max-width: 20vw;
      }
    }
  }
}
