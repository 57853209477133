@import '../../theme.scss';

.container {
  display: flex;
  justify-content: space-between;

  .header {
    font-family: Bellefair, serif;
    color: $color-light;
    text-align: center;
    flex: 0 0 25%;
    margin-top: 8px;
  }

  .input {
    flex: 0 0 75%;

    .inputText {
      background-color: $color-dark;
      width: 100%;
      border-radius: 16px;
      padding-left: 16px;
      margin-bottom: 8px;

      :global(.MuiInputBase-input) {
        color: $color-light;
        font-family: Bellefair, serif;
        margin-top: 2px;
      }
    }
  }
}
