@import '../../theme.scss';

.container {
	background: linear-gradient(15deg, $color-light 0%, $color-midtone 250%);
	color: $color-dark;
	margin: 16px 0px;
	border: 0;
	border-radius: 8px;
	display: flex;
	align-items: center;

	.icon {
		flex: 0 0 128px;
		text-align: center;
		font-size: 48px;
		min-height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content {
		padding: 16px 16px 16px 0px;
	}
}

ul {
	padding: 0;
	margin: 8px 0px;

	li {
		margin-left: 20px;
		margin-bottom: 2px;
	}
}
