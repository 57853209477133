@import '../../theme.scss';

.container {
  padding-top: 24px;

  .updateContainer {
    margin-top: 32px;
    margin-bottom: 32px;
    padding-top: 16px;
    border-top: 1px solid $color-light;
    display: flex;
    justify-content: center;

    .button {
      padding: 4px 16px;
      font-size: 16px;
      border-radius: 16px;
      border: 0px;
      font-family: Bellefair, serif;
      color: $color-dark;
      background-color: $color-light;
      cursor: pointer;

      &.disabled {
        background-color: $color-midtone;
        color: $color-dark;
        cursor: not-allowed;
      }

      &.error {
        background-color: $color-error-light;
        color: $color-error;
      }
    }
  }

  .nonEditableNotes {
    .header {
      font-size: 20px;
      border-bottom: 1px solid $color-midtone;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .obsolescence {
      background-color: $color-error-light;
      color: $color-error;
      padding: 4px 8px;
      border-left: 8px solid $color-error;
      display: inline-block;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .content {
      user-select: text;
    }
  }
}

.hr {
  border: 1px dashed $color-midtone;
  margin: 16px 0;
}

.newPasswordMismatch {
  background-color: $color-error-light;
  color: $color-error;
  margin-top: 8px;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 16px
}

.impersonateSecurity {
  .padRight {
    margin-right: 4px;
    text-decoration: underline;
    cursor: pointer;
  }

  .emailAddress {
    color: $color-light;
    margin-left: 4px;
  }
}
