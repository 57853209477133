@import '../../theme.scss';

.container {
  height: calc(100vh - 44px);
  width: calc(100vw - 240px);
  margin-top: -8px;
  min-width: 480px;
  background-color: $color-off-dark;

  .wrapper {

    max-width: 640px;
    margin: 0 auto;

    .header {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      font-size: 18px;

      .return {
        cursor: pointer;
        text-decoration: underline;
      }

      .playerID {

      }
    }

    .tabbed {
      display: flex;
      margin-top: 16px;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .tab {
        padding: 4px 12px;
        padding-top: 6px;
        border: 1px solid $color-midtone;
        border-bottom: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        margin: 0px 2px;
        cursor: pointer;
        white-space: nowrap;

        &.selected {
          background-color: $color-light;
          color: $color-dark;
          border: 1px solid $color-light;
          cursor: default;
        }

        &:hover {
          filter: brightness(125%);
        }

        &:first-of-type {
          margin-left: 0;
        }

        .icon {
          font-size: 16px;
          margin-bottom: -2px;
          margin-right: 4px;
        }
      }
    }

    .tabContent {
      border-top: 1px solid $color-light;
      user-select: text;
    }
  }
}

@media screen and (max-width: 740px) {
  .container {
    width: 100vw;

    .wrapper {
      width: calc(100vw - 16px);
      min-width: unset;
      max-width: 480px;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;

    .wrapper {
      width: calc(100vw - 16px);
      max-width: unset;
      min-width: unset;
    }
  }
}
