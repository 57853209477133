@import '../theme.scss';

@mixin inverted() {
  background-color: $color-light;

  :global(.MuiListItemIcon-root) {
    color: $color-dark;
  }

  :global(.MuiListItemText-root) {
    :global(.MuiTypography-root) {
      color: $color-dark;
    }
  }
}

.sidebar {
  background-color: $color-dark;
  height: calc(100vh - 44px);
  z-index: 500;
  padding-top: 4px;
  box-shadow: 0px 0px 20px 0px #222;
  width: 240px;

  &.hideOnPrint {
    display: none;
  }

  .sharedRow {
    display: flex;
    justify-content: space-between;
    font-family: Bellefair, serif;
    font-size: 20px;

    .cell {
      padding: 14px 16px 12px 16px;
      flex: 1 1 90%;
      width: calc(100% - 64px);
      cursor: pointer;
      color: $color-light;
      text-decoration: none;

      &.selected {
        background-color: $color-light;
        color: $color-dark;
      }

      &.iconOnly {
        flex: 0 0 24px;
        max-width: 24px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 12px;
        padding-bottom: 8px;
      }

      &:hover {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }

  :global(.MuiPaper-root) {
    background-color: $color-dark !important;

    :global(.MuiButtonBase-root) {
      &:hover {
        @include inverted();
      }

      &.selected {
        @include inverted();
      }

      :global(.MuiListItemIcon-root) {
        min-width: 36px;
        color: $color-light;
      }

      :global(.MuiListItemText-root) {
        :global(.MuiTypography-root) {
          font-family: 'Veteran Typewriter', serif;
          color: $color-light;
        }
      }
    }
  }
}

@media (min-width: 512px) {
  .playerMetadata {
    max-width: 33vw;
  }
}

@media (max-width: 739px) {
  .sidebar {
    z-index: 500;
    position: fixed;

    &.opened {
      animation: slide-in 0.3s ease-in-out;
      transform: translateX(0);
    }

    &.holstered {
      animation: slide-out 0.3s ease-in-out;
      transform: translateX(-100vw);
    }

    &.animationHidden {
      display: none;
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100vw);
  }
}
