@import '../../theme.scss';

.transactionGroup {
	margin-bottom: 16px;

	.header {
		border-bottom: 1px solid $color-midtone;

		.chronology {
			display: flex;
			justify-content: flex-start;


			.timestamp {
				flex: 0 0 96px;
			}

			.legit {
				flex: 1 1 64px;
				margin-left: 16px;
				margin-top: -6px;
				text-align: right;
			}
		}

		.eventName {
			text-align: left;
		}
	}

	.transactions {
		border-left: 1px dashed $color-midtone;

		&.invalidated {
			text-decoration: line-through;

			.transaction {
				color: $color-midtone;
			}
		}

		.transaction {
			display: flex;
			margin: 2px 0px;

			&.withHighlight {
				color: $color-midtone;

				&.highlight {
					color: $color-light;

					&.outbound {
						color: $color-error;
						filter: brightness(125%);
					}
				}
			}

			&:first-of-type {
				margin-top: 4px;
			}

			&:hover {
				background-color: $color-light;
				color: $color-dark;

				&.withHighlight {
					background-color: $color-light;
					color: $color-dark;
				}

				.transactionPartner {
					.autoPopup {
						background-color: $color-off-dark;
						color: $color-light;
						z-index: 999;

						.link {
							text-decoration: underline;
							padding: 4px 0px;
							cursor: pointer;
						}
					}
				}

				.itemName {
					.unique {
						color: $color-combat-1;
					}
				}
			}

			.transactionId {
				flex: 0 0 64px;
				text-align: right;
				margin-right: 8px;
			}

			.transactionPartner {
				flex: 0 0 128px;
				margin-right: 8px;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: ellipis;

				&:hover {
					.autoPopup {
						display: block;
						position: absolute;
						border-radius: 8px;
					}
				}

				.autoPopup {
					display: none;
					padding: 4px 8px;
					box-shadow: 0px 4px 20px 0px $color-dark;
					margin-top: -32px;
					margin-left: 64px;
					border: 1px solid $color-midtone;
					opacity: 0.95;
				}

			}

			.itemName {
				flex: 1 1;

				.augmentationAndName {
					display: flex;
				}

				.unique {
					color: $color-anomaly-0;
					filter: brightness(200%);
				}
			}

			.transactLeft {
				display: flex;
				flex: 0 0 48px;
				margin-right: 8px;

				.itemAmount {
					text-align: right;
					width: 32px;
				}
			}

			.transactRight {
				display: flex;
				flex: 0 0 32px;
				justify-content: flex-end;
			}
		}
	}

	.description {
		border-top: 1px dashed $color-midtone;
		border-left: 1px dashed $color-midtone;
		padding: 8px;

		&.editable {
			width: calc(100% - 18px);
			background-color: $color-dark;
			color: $color-light;
			border-radius: 4px;
		}
	}
}

.blinking {
	animation: blinker 1.5s ease-in-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 25%;
  }
}
