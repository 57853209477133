@import '../../theme.scss';

.container {
  .membership {
    font-family: Bellefair, serif;
    margin-top: 16px;
    text-align: left;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .membershipHistory {
    width: 100%;
    font-family: Bellefair, serif;
    margin-top: 8px;

    .title {
      font-size: 20px;
      border-bottom: 1px solid $color-midtone;
      margin-bottom: 8px;
    }

    .content {
      .row {
        color: $color-light;
        display: flex;
        font-size: 16px;
        font-family: monospace, serif;

        .marker {
          flex: 0 0 32px;
          font-size: 24px;
          margin-top: -8px;
          margin-bottom: -8px;
          margin-right: 8px;
          text-align: right;
        }

        .date {
          display: flex;

          .picker {
            width: 102px;

            :global(.react-datepicker-wrapper) {
              :global(.react-datepicker__input-container) {
                :global(input) {
                  font-size: 16px;
                  width: 96px;
                  background-color: transparent;
                  font-family: monospace, serif;
                  padding: 0;
                  text-decoration: underline dashed;
                  text-underline-position: under;
                }
              }
            }
          }

          .unpickable {
            margin-left: 2px;
          }
        }

        .remove {
          text-decoration: underline;
          font-family: Bellefair, serif;
          margin-left: 8px;
          cursor: pointer;
        }

        &.expired {
          color: $color-midtone;
        }
      }
    }
  }
}
