@import '../../theme.scss';

.container {
  font-family: Bellefair, serif;
  color: $color-light;
  max-width: 480px;
  margin: 0 auto;
  margin-top: 80px;
  text-align: center;
}
