@import '../../theme.scss';

.taxonomySelect {
	cursor: pointer;

	&.blinking {
		.select {
			background-color: $color-combat-3;
			color: $color-error-light;
			animation: blinker 2s ease-in-out infinite;
		}
	}

	.select {
		font-family: Bellefair, serif;
		color: $color-light;
		background: transparent;
		padding: 0px 8px;

		&:focus {
			background: transparent;
		}
	}

	.icon {
		margin-top: -1px;
		margin-right: -6px;
	}
}

.menu {
	opacity: 0.9;

	:global(.MuiMenu-paper) {
		background-color: $color-dark;
    max-height: 33vh;
    border-radius: 16px;
	}

	.option {
		font-family: Bellefair, serif;
		color: $color-light;
		padding: 4px 16px;
		min-height: 24px;
		cursor: pointer;

		&:global(.Mui-selected) {
			background-color: $color-light;
			color: $color-dark;
			cursor: default;

			&:hover {
				background-color: $color-light;
				color: $color-dark;
			}
		}
	}
}

@keyframes blinker {
	50% {
		filter: brightness(150%);
	}
}
