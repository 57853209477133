@import '../../theme.scss';

.react-datepicker-wrapper {
  width: calc(100% - 64px);
  margin-bottom: 8px;

  .react-datepicker__input-container {
    margin-right: -32px;
    input {
      padding: 8px 16px;
      border-radius: 16px;
      background-color: $color-dark;
      color: $color-light;
      border: 0;
      width: 100%;
      cursor: pointer;

      font-size: 14px;
      font-family: Bellefair, serif;
    }
  }
}


.react-datepicker__month-container {
  font-family: Bellefair, serif;
  font-size: 16px;
}

.react-datepicker__time-list, .react-datepicker-time__header {
  font-family: Bellefair, serif;
  font-size: 16px;
}

.react-datepicker__close-icon {
  margin-right: -24px;

  &:after {
    background-color: $color-light;
    color: $color-dark;
  }
}
