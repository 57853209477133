@import '../../theme.scss';

@mixin input() {
  font-family: Alegreya, serif;
  text-decoration: underline dashed;
  text-underline-position: under;
  margin-left: 8px;
}

.container {
  margin-top: 8px;

  .header {
    font-size: 20px;
    border-bottom: 1px solid $color-dark;

    .small {
      font-size: 14px;
    }
  }

  .content {
    font-size: 16px;

    .noCxb, .noCurrentEvents {
      padding-top: 8px;
    }
    .legacy {
      .legacyHeader {
        text-align: left;
        border-top: 1px solid $color-dark;
        padding: 4px 0px;
      }

      .legacyDatum {
        display: flex;
        justify-content: space-around;
        padding-bottom: 8px;
      }

      .legacyEvents {
        border-top: 1px solid $color-dark;

        padding-top:8px;
      }

    }

    .row {
      display: flex;
      align-items: center;

      &.extraBuild {
        .left {
          flex: 0 0 20%;
        }

        .middle {
          flex: 0 0 60%;
        }
      }
      .left {
        flex: 0 0 10%;

        .date {
          text-align: right;

          .clear {
            text-align: center;
            margin-top: -2px;
            cursor: pointer;
          }
        }
      }

      .middle {
        flex: 0 0 70%;

        &.history {
          flex: 0 0 80%;
        }
        .reason {
          .input {
            @include input();
            width: 100%;
          }

          .text {
            font-size: 16px;
            margin-left: 8px;
          }
        }

        .event {
          display: flex;
          align-items: center;

          .homeGameMarker {
            flex: 0 0 32px;
            margin-right: 4px;
            text-align: right;
            font-weight: bold;

          }

          .text {

          }
        }

        .grantor {
          margin-left: 8px;
          margin-top: -4px;
          font-size: 14px;
        }
      }

      .right {
        flex: 0 0 20%;

        &.history {
          flex: 0 0 10%;
        }

        .amount {
          .input {
            @include input();
            text-align: right;
          }

          .text {
            font-size: 16px;
            text-align: right;
          }
        }
      }
    }
  }

  .button {
    background-color: $color-dark;
    color: $color-light;
    padding: 8px 16px;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    &:hover {
      filter: brightness(125%);
    }

    &.disabled {
      background-color: $color-midtone;
      color: $color-light;
      cursor: not-allowed;
    }
  }

  .addRow {
    display: flex;
    align-items: center;
    margin-top: 8px;
    border-top: 1px solid $color-dark;
    .addExtraBuild {

      background-color: $color-dark;
      color: $color-light;
      padding: 8px 16px;
      display: inline-flex;
      border-radius: 16px;
      margin-bottom: 8px;
      margin-top: 8px;
      cursor: pointer;
      height: 20px;

      .text {
        margin-top: 2px;
        margin-left: 16px;
        margin-right: 8px;
      }
    }

    .extraWarning {
      flex: 1 1 40%;
      padding: 8px 0px 8px 8px;
      color: $color-dark;
    }
  }
}

.inputRightJustify {
  text-align: right;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: $color-dark;

  .icon {
    font-size: 14px;
    margin-bottom: -1px;
    margin-left: 4px;
  }
}

.cumulativeChange {
  border: 1px solid $color-dark;
  border-left: 8px solid $color-dark;
  padding: 4px;
  margin-top: 4px;
  cursor: pointer;
  color: $color-dark;
  text-decoration: none;
  display: block;

  &.statv2 {
    animation: orange-lit 2s ease-in-out infinite;
  }

  .icon {
    font-size: 14px;
    margin-bottom: -1px;
    margin-left: 4px;
  }
}

.hideousLink {
  text-decoration: none;
  cursor: pointer;
  color: $color-dark;

  .icon {
    display: none;
    font-size: 14px;
    margin-bottom: -1px;
    margin-left: 4px;
  }

  &:hover {
    text-decoration: underline;

    .icon {
      display: inline-block;
    }
  }
}

.leftBump {
  margin-left: 4px;
}

.timeJump {
  text-align: center;
  font-size: 18px;
  padding: 4px 0px;
  margin: 8px 0px;
  background: linear-gradient(90deg, $color-light 0%, $color-light 10%, $color-dark 50%, $color-light 90%, $color-light 100%);
  color: $color-light;
}

@keyframes orange-lit {
  0% {
    border-color: $color-dark;
    color: $color-dark;
  }

  50% {
    border-color: $color-error;
    color: $color-error;
  }

  100% {
    border-color: $color-dark;
    color: $color-dark;
  }
}

@media (max-width: 480px) {
  .container {
    .content {
      .row {
        .left{
          flex: 0 0 10%;
        }

        &.extraBuild {
          .left {
            flex: 0 0 25%;
          }

          .middle {
            flex: 0 0 55%;
          }
        }

        .middle {
          flex: 0 0 65%;

          &.history {
            flex: 0 0 75%;
          }
        }

        .right {
          flex: 0 0 20%;

          &.history {
            flex: 0 0 15%;
          }
        }
      }
    }
  }
}
