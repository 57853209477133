@import '../theme.scss';

.container {
  .base {
    width: 100%;
    height: 24px;

    .holeContainer {
      display: flex;
      justify-content: flex-start;
      position: relative;

      .flat {

      }
      .hole {
        height: 8px;
        width: 8px;
        border-radius: 8px;
        background-color: $color-light;
        margin-top: 7px;

        &.rhombus {
          border-radius: 0;
          transform: rotate(45deg);
        }

        &.muted {
          filter: brightness(0.4);
        }
      }
    }
  }
}
