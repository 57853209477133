@import '../../theme.scss';

.container {
  border-bottom: 1px solid $color-midtone;
  border-top: 1px solid $color-midtone;
  padding: 8px 0px;
  margin: 8px 0px;
  border-left: 4px solid $color-midtone;
  padding-left: 8px;
  font-family: 'Bellefair', serif;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 20px;
    }

    .remove {
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
      border: 0;
      background: transparent;
      font-family: 'Bellefair', serif;
      font-size: 16px;
      color: $color-light;
    }
  }

  .descriptions {
    padding-top: 16px;

    .descriptionStruct {
      margin: 8px 0px;

      .controlRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;

        .numeric {
          background: transparent;
          color: $color-light;
          text-align: right;
          border: 0;
          border-radius: 16px;
          font-family: 'Bellefair', serif;
          font-size: 16px;
          text-decoration: underline;
        }

        .quantityGroup {
          display: flex;
          justify-content: space-between;
          flex: 1 1 25%;
        }

        .remove {
          flex: 1 1 75%;
          text-align: right;
          font-family: 'Bellefair', serif;
          background: transparent;
          border: 0;
          text-decoration: underline;
          color: $color-light;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .textRow {
        .textarea {
          width: calc(100% - 16px);
          background-color: $color-dark;
          color: $color-light;
          padding: 8px;
          border-radius: 4px;
        }
      }
    }
  }

  .addDescription {
    font-family: 'Bellefair', serif;
    font-size: 14px;
    background-color: $color-light;
    color: $color-dark;
    border: 0;
    padding: 4px 16px;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 16px;

    &:hover {
      filter: brightness(125%);
    }
  }
}
