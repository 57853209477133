@import '../theme.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  padding-top: 20vh;
  text-align: center;

  .title {
    width: 100%;
    font-size: 24px;
    margin-bottom: 12px;
  }

  .subtitle {
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
  }

  .actionable {
    width: 100%;
    font-size: 18px;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
