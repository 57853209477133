@import '../theme.scss';

.descriptionLead {
  font-size: 20px;
  margin-top: 16px;
  border-bottom: 1px solid $color-midtone;
  padding-bottom: 2px;
  margin-bottom: 4px;
}

.descriptionEntry {
  margin-top: 0px;
}
