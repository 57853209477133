@import '../../theme.scss';

.thumb {
  margin-top: 9px;
  margin-left: 4px;
  cursor: pointer;
}

.overlayContainer {
  z-index: 51;
  width: 75vw;
  max-width: 256px;
}

.overlay {
  font-family: 'Bellefair', serif;
  font-size: 16px;
  z-index: 25;

  background-color: $color-off-dark;
  color: $color-light;
  margin: 0;
  padding: 24px 16px 16px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid $color-midtone;
  border-top: 0;
}

.statBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $color-dark;
  border-radius: 16px;
  margin: 2px 0px;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  color: $color-light;
}

.skillCell {
  width: 100%;
  padding: 0px 12px;
}

.loreGrid {
  margin-top: 6px;
}

.buildBar {
  text-align: center;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  color: $color-light;
  flex-wrap: wrap;
  margin: 0 0px 2px 2px;
  padding-top: 10px;
  padding-bottom: 8px;
  width: 100%;
  border-radius: 16px;
  background-color: $color-dark;
  user-select: none;
  cursor: pointer;

  .text {
    color: $color-light;
  }
}