@import '../../theme.scss';

.noHorizontalScroll {
  overflow-x: hidden !important;
}

.container {
  padding: 0px 4px;
  max-width: 640px;
  width: calc(100vw - 256px);
  min-width: 464px;
  margin: 0 auto;
  margin-top: 64px;
  margin-bottom: 64px;

  .eventInfo {
    font-family: Bellefair, serif;
    color: $color-light;

    .eventName {
      font-size: 24px;
    }
  }

  .userSelectable {
    user-select: text;

    .numeric {
      font-family: monospace, serif;
    }

    .eventProperty {
      padding: 2px 0px;
      &.header {
        align-items: baseline;
        .left {
          font-size: 24px;
        }
      }
      &.footer {
        padding-top: 4px;
        font-weight: bold;
        align-items: baseline;
        .right {
          font-size: 20px;
        }
      }
    }
  }

  .eventProperty {
    display: flex;
    font-family: Bellefair, serif;
    color: $color-light;

    &.header {
      border-bottom: 1px solid $color-light;
      .splitMid, .splitRight {
        text-align: right;
      }
    }

    &.footer {
      border-top: 1px solid $color-light;
    }

    .left {
      flex: 0 1 50%;

      &.masked {
        color: $color-midtone;
      }
    }

    .splitMid {
      flex: 0 1 25%;
      &.numeric {
        text-align: right;
      }
    }

    .splitRight {
      flex: 0 1 25%;
      &.numeric {
        text-align: right;
      }
    }

    .pointer {
      cursor: pointer;
    }

    .mid {
      flex: 0 1 25%;
      &.numeric {
        text-align: right;
      }
    }

    .right {
      flex: 0 1 25%;

      &.numeric {
        text-align: right;
      }
    }
  }

  .playerGroup {
    font-size: 24px;
    font-family: Bellefair, serif;
    color: $color-light;
    margin-top: 32px;

    .reloadIcon {
      cursor: pointer;
      margin-right: 8px;
    }

    .rawData {
      font-size: 14px;
      user-select: all;
      padding-top: 8px;
      cursor: pointer;
      overflow-x: auto;
      white-space: nowrap;
    }

    .subtitle {
      font-size: 14px;
      border-top: 1px solid $color-light;
      border-bottom: 1px solid $color-light;
      padding: 4px 0px;
    }

    .shiftsContainer {
      .shiftsMetadata {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .amount {
          flex: 1 1 50%;
          text-align: left;
          font-size: 16px;

          &:first-of-type {
            padding-right: 8px;
          }

          .scale {
            height: 4px;
            background-color: $color-light;
            width: 100%;
            margin-top: 2px;
            border-radius: 16px;
          }
        }
      }

      .fulfillmentToggle {
        display: flex;
        font-size: 16px;
        align-items: center;
        margin-bottom: 8px;

        .toggle {
          margin-right: 16px;
        }
      }

      .explanation {
        font-size: 18px;
        margin-bottom: 16px;
        border-top: 1px solid $color-light;
        border-bottom: 1px solid $color-light;
        padding-top: 8px;
        padding-bottom: 4px;

        .code {
          background-color: $color-light;
          color: $color-dark;
          padding: 0px 4px;
          border-radius: 4px;
          font-family: monospace, serif;
        }
      }
    }

    .header {
      display: flex;
      font-size: 16px;
      border-top: 1px solid $color-light;
      border-bottom: 1px solid $color-light;

      .cell {
        padding: 8px;

        &.playerID {
          flex: 0 0 10%;
          text-align: right;
        }

        &.playerName {
          flex: 0 0 60%;
        }

        &.paid {
          flex: 0 0 8%;
        }
      }
    }

    .content {
      max-height: 67vh;
      min-height: 128px;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: 0px 10px 10px -10px $color-midtone;
    }

    .shiftDay {
      margin-bottom: 16px;

      .dayHeader {
        font-size: 20px;
        border-bottom: 1px solid $color-light;
        margin-bottom: 8px;
      }

      .shiftSlot {
        padding-left: 16px;
        font-size: 16px;


        .shiftSlotHeader {
          display: flex;
          justify-content: space-between;

          .shiftSlotLabel {

            &.disabled {
              color: $color-midtone;
              text-decoration: line-through;
            }
          }

          .shiftLimit {
            flex: 0 0 128px;
            text-align: right;
          }
        }

        .attendeesContainer {
          display: flex;
          justify-content: flex-start;
          font-size: 16px;
          padding-left: 16px;

          .buffer {
            flex: 0 0 10%;
            background-color: $color-midtone;
          }
          .playerID {
            flex: 0 0 20%;
            text-align: right;

            .paid {
              margin-left: 4px;

              &.muted {
                visibility: hidden;
              }
            }
          }

          .attendance {
            display: none;
          }

          .playerName {
            padding-left: 16px;
            cursor: pointer;

            .clickable {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.inputLimit {
  padding: 0;
  margin: 0;
  width: 32px;

  :global(input) {
    padding: 0;
    font-family: Bellefair, serif;
    color: $color-light;
    text-decoration: underline dashed;
    text-underline-position: under;
  }
}

.clickable {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);

    .playerGroup {
      .header {
        .cell {
          &.playerName {
            flex: 0 1 50%;
          }

          &.paid {
            flex: 0 0 11%;
          }
        }
      }
    }
  }
}

@media print {
  .container {
    display: contents;

    .playerGroup {
      .shiftDay {
        .shiftSlot {
          .attendeesContainer {
            .attendance {
              display: flex;
              padding: 0px 8px;

              .attendanceBox {
                width: 32px;
                height: 16px;
                margin-top: 0px;
                border-bottom: 1px solid $color-light;

                &:first-of-type {
                  margin-right: -1px;
                }
              }
            }
          }
        }
      }
    }
  }

  .noHorizontalScroll {
    max-height: none !important;
  }

  .belowControl {
    max-height: none !important;
  }


}
