.row {
  max-width: 480px;
  margin: 0 auto;
  transition: all 300ms ease-in-out;

  &.entering, &.entered {
    margin-top: 0;
    opacity: 1;
  }

  &.exiting, &.exited {
    margin-top: -36px;
    opacity: 0;
    height: 36px;
    pointer-events: none;
  }
}
