@import '../../theme.scss';

.button {
  background-color: $color-light;
  color: $color-dark;
  padding: 8px 16px;
  margin-bottom: 8px;
  text-align: center;
  border-radius: 16px;
  font-family: Bellefair, serif;
  cursor: pointer;

  &.invalid {
    background-color: $color-midtone;
    color: $color-dark;
    cursor: not-allowed;

    &:hover {
      filter: none;
    }
  }

  &:hover {
    filter: brightness(125%);
  }
}
