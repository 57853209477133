@import '../../theme.scss';

.container {
  background-color: $color-light;
  color: $color-dark;
  margin-bottom: 64px;

  .landscape {
    border-bottom: 1px solid $color-dark;
    border-bottom-style: dashed;
    padding: 16px;
    text-align: center;

    .drLabel {

    }

    .eventName {
      font-size: 24px;
      display: inline-block;
      border-top: 1px solid $color-dark;
      border-bottom: 1px solid $color-dark;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .logistics {
      .row {
        display: flex;
        justify-content: center;

        .left {
          flex: 0 0 50%;
          border-right: 1px solid $color-dark;
          text-align: right;
          padding-right: 8px;
        }

        .right {
          flex: 0 0 50%;
          text-align: left;
          padding-left: 8px;
        }
      }
    }
  }

  .portrait {
    .header {
      display: flex;
      justify-content: space-between;
      margin: 0 16px;
      margin-top: 8px;

      .eventName {
        text-align: left;
      }

      .playerName {
        text-align: right;
      }
    }

    .qrCode {
      margin: 8px auto;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
    }

    .listGroup {
      padding: 16px;
      padding-bottom: 8px;

      .header {
        font-size: 20px;
        border-bottom: 1px solid $color-dark;
        margin: 0 0 4px 0;
      }

      .content {
        .list {
          display: flex;
          align-items: center;

          .shiftKind {
            flex: 0 0 80px;
            text-align: right;
            margin-right: 8px;
          }

          .shiftLabel {

          }
        }
      }

      &:last-of-type {
        padding-bottom: 32px;
      }
    }
  }
}

.muted {
  color: $color-midtone;
}

.underlined {
  text-decoration: underline;
}

@media print {
  .container {
    display: block !important;
  }
}
