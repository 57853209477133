@import '../../theme.scss';

.container {
  display: flex;
  flex-wrap: wrap;

  .row {
    display: flex;
    align-items: center;
    margin-top: 8px;
    width: 100%;

    .header {
      flex: 0 0 25%;
      margin-top: 4px;
    }

    .combo {
      display: flex;
      align-items: center;

      .inputBox {
        margin-right: 16px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }

        .inviterInput {
          background-color: $color-dark;
          color: $color-light;
          border-radius: 16px;
          font-family: Bellefair, serif;
          border: 0;
          padding: 8px 8px;
          font-size: 14px;
          width: 80px;
          text-align: right;
        }
      }

      .helper {

      }
    }

    .selector {
      flex: 0 0 75%;

      .base {
        width: 100%;
        color: $color-light;
        font-family: Bellefair, serif;
        background-color: $color-dark;
        border-radius: 16px;
        padding: 0px 16px;

        .icon {
          color: $color-light;
          margin-right: 8px;
        }
      }
    }

    .buildBooster {
      flex: 0 0 75%;
      display: flex;
      margin-top: 4px;
      margin-bottom: 4px;

      .slider {
        flex: 0 0 64px;
        margin-top: 4px;
        margin-right: 16px;
        padding-left: 8px;

        &.national {
          flex: 0 0 67%;
        }

        &.premiere, &.virtual {
          flex: 0 0 128px;
        }

        :global(.rc-slider-rail) {
          background-color: $color-midtone;
        }

        :global(.rc-slider-track) {
          background-color: $color-anomaly-0;
        }

        :global(.rc-slider-step) {
          :global(.rc-slider-dot) {
            background-color: $color-light;
            border-color: $color-light;
          }
        }
        :global(.rc-slider-handle) {
          background-color: $color-light;
        }
      }

      .boostAmount {
        flex: 1 1 33%;
        margin-top: 2px;
      }
    }
  }
}

.menu {
  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    margin-top: 56px;
    margin-left: 0px;
    border-radius: 16px;
  }

  .option {
    font-family: Bellefair, serif;
    padding: 4px 16px;
    //width: 160px;
    color: $color-light;
    cursor: pointer;

    &:hover {
      color: $color-light;
    }

    &.wide {
      width: 224px;
    }
  }
}
