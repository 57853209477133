@import '../../theme.scss';

.container {
  font-family: Bellefair, serif;
  color: $color-light;
  user-select: all;

  .noCheckout {
    margin: 12px 16px 12px 16px;
  }

  .eventAttendees {
    max-height: calc(100vh - 448px);
    overflow-y: auto;
    box-shadow: 0px 10px 10px -10px $color-midtone;
    user-select: text;

    .attendeeRow {
      display: flex;
      max-width: 240px;
      padding: 2px 0;
      cursor: pointer;

      .playerID {
        flex: 0 0 64px;
        text-align: right;
      }

      .playerName {
        margin-left: 8px;
      }

      &.highlighted {
        background-color: $color-light;
        color: $color-dark;
        font-weight: bold;
        font-size: 16px;
      }

      &:hover {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }

  .eventInfo {
    max-width: 224px;
    margin-left: 16px;
    border-bottom: 1px solid $color-midtone;
    
    .title {
      font-size: 16px;
    }

    .eventName {
      padding-right: 16px;
    }
  }
}
