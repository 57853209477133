@import '../../theme.scss';

.privileged {
  margin-top: 10px;
  margin-left: 4px;
  margin-bottom: -6px;
  cursor: pointer;
}

.overlayContainer {
  z-index: 51;
  width: 75vw;
  max-width: 256px;
}

.overlay {
  font-family: 'Bellefair', serif;
  font-size: 16px;
  z-index: 25;

  background-color: $color-off-dark;
  color: $color-light;
  margin: 0;
  padding: 32px 16px 16px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid $color-midtone;
  border-top: 0;

  .fakeClose {
    text-align: center;
    color: $color-dark;
    background-color: $color-light;
    margin: 16px -16px -16px -16px;
    padding: 8px 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
  }

  .tabControl {
    display: flex;
    margin-bottom: 8px;
    padding-right: 6px;

    .tab {
      background-color: $color-dark;
      border: 1px solid $color-light;
      width: 100%;
      text-align: center;
      padding: 4px 0px;
      cursor: pointer;

      &.active {
        cursor: not-allowed;
        background-color: $color-light;
        color: $color-dark;
      }

      &:first-of-type {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-right: 0;
      }

      &:last-of-type {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
}

.controlRow {
  display: flex;
  justify-content: center;

  .text {
    text-align: right;
    margin-top: 4px;
  }

  .toggle {
    margin-left: 8px;
  }
}
