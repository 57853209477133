@import '../../theme.scss';

.container {
  font-family: Bellefair, serif;
  color: $color-light;

  overflow-y: auto;
  max-height: calc(100vh - 106px);

  width: 100%;

  .wrapper {
    max-width: 480px;
    margin: 0 auto;

    .wide {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      margin-bottom: 16px;

      .control {
        background-color: $color-light;
        color: $color-dark;
        display: inline;
        margin: 0 auto;
        padding: 8px 16px;
        border-radius: 16px;
        cursor: pointer;

        &:hover {
          filter: brightness(125%);
        }
      }
    }
  }
}
