@import '../../theme.scss';

.textarea {
  margin-top: 8px;
  font-family: Bellefair, serif;
  font-size: 16px;
  width: calc(100% - 8px);
  background-color: $color-dark;
  color: $color-light;
  border-radius: 4px;
  border-color: $color-midtone;
  outline: none;

  &:focus, &:hover {
    border-color: $color-light;
  }
}

.noneditableText {
  font-family: Bellefair, serif;
  font-size: 16px;
  width: 100%;
  color: $color-light;
}
