@import '../theme.scss';

.container {
  max-width: 640px;
  width: calc(100vw - 256px);
  min-width: 464px;
  margin: 0 auto;
  margin-bottom: 64px;
  overflow: auto;
  max-height: 75vh;

  .disclaimer {
    border-bottom: 1px solid $color-light;
    margin-bottom: 8px;
  }

  .gridTable {
    border-collapse: collapse;
    border-spacing: 0;

    .stickyColumn {
      max-width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      position: sticky;
      left: 0;
      background-color: $color-off-dark;
      box-shadow: 2px 14px 20px 0px $color-dark;
      padding: 4px;
      z-index: 6;
    }

    .stickyRow {
      .stickyColumn {
        position: sticky;
        top: 0;
        background-color: $color-off-dark;
        z-index: 8;
        box-shadow: 8px 0px 20px 0px $color-dark;
        border-bottom: 1px solid $color-light;
        padding: 4px 8px;
      }

      .stickyCell {
        position: sticky;
        top: 0px;
        z-index: 7;
        background-color: $color-off-dark;
        box-shadow: 8px 0px 20px 0px $color-dark;
        border-bottom: 1px solid $color-light;
      }
    }

    .cell {
      text-align: right;
      min-width: 96px;
    }
  }

  .footerDiv {
    z-index: 20;
    background-color: $color-off-dark;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);
  }
}
