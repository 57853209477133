@import '../../theme.scss';

.container {
  margin-top: 8px;

  .textSegments {
    display: flex;
    justify-content: center;
    width: 100%;

    .segments {
      &:last-of-type {
        .segment {
          padding-left: 1px;
        }
      }

      .segment {
        width: 100%;
        text-align: center;
        padding-left: 3px;

        &.muted {
          color: $color-midtone;
        }
      }
    }
  }

  .chart {
    display: flex;
    justify-content: center;
    z-index: 2;

    .circle {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: $color-dark;
      border: 1px solid $color-dark;
      z-index: 3;

      &.unfilled {
        background: unset;
        background-color: $color-light;
        border: 1px solid $color-midtone;
      }
    }

    .segment {
      display: flex;

      .text {
        width: 100%;
      }

      .bar {
        height: 2px;
        width: 100%;
        border-top: 1px solid $color-midtone;
        border-bottom: 1px solid $color-midtone;

        background-color: transparent;
        margin-top: 3px;
        z-index: 2;
      }
    }
  }

  .filledBarWrapper {
    width: calc(100% - 2px);
    margin-top: -10px;
    margin-left: 2px;
    z-index: 3;

    .filledBar {
      height: 4px;
      background-color: $color-dark;
      margin-top: 3px;
      background: linear-gradient(
        -90deg,
        $color-dark 0,
        $color-dark 80%,
        $color-light 90%,
        $color-dark 100%
      );
      animation: gradient 4s ease-in-out infinite;
      background-size: 400% 400%;
      z-index: 2;
    }
  }
}

.tierSystem {
  padding: 4px;
  margin-top: 4px;
  cursor: pointer;
  color: $color-dark;
  text-decoration: none;
  display: block;
  text-align: right;

  .icon {
    font-size: 14px;
    margin-bottom: -1px;
    margin-left: 4px;
  }
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: -50% 0%;
  }
}
