body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /* PWA Compatibility - See https://medium.com/appscope/designing-native-like-progressive-web-apps-for-ios-1b3cdda1d0e8 */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

@font-face {
  font-family: 'Acta Symbols W95 Circles';
  src: url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.eot');
  /* IE9*/
  src: url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.woff2') format('woff2'),
    /* chrome、firefox */
    url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.woff') format('woff'),
    /* chrome、firefox */
    url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url('./assets/acta-symbols-w95-circles/acta-symbols-w95-circles.svg#Acta Symbols W95 Circles') format('svg');
  /* iOS 4.1- */
}

@font-face {
  font-family: 'Veteran Typewriter';
  src: url('./assets/veteran-typewriter/veteran-typewriter.woff2') format('woff2'),
    url('./assets/veteran-typewriter/veteran-typewriter.woff') format('woff'),
    url('./assets/veteran-typewriter/veteran-typewriter.ttf') format('truetype');
}

@font-face {
  font-family: 'Remington Noiseless';
  src: url('./assets/remington-noiseless/remington-noiseless.woff2') format('woff2'),
    url('./assets/remington-noiseless/remington-noiseless.woff') format('woff'),
    url('./assets/remington-noiseless/remington-noiseless.ttf') format('truetype');
}