@import '../../theme.scss';

@mixin planned($color, $gray) {
  &.planned {
    background: linear-gradient(
      135deg,
      $gray 49%,
      $color 50%,
    )
  }
}

@mixin longSkillWidth() {
  width: calc(44% - 21px);
}
@mixin pfxSkillWidth() {
  width: calc(56% - 41px);
  text-overflow: clip;
  overflow-x: visible;
  white-space: nowrap;
}
@mixin triafter($color) {
  position: absolute;
  top: 0px;
  right: -15.5px;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent $color;
  &:hover {
    filter: brightness(125%);
  }
}
@mixin trixtend($color, $index, $reflective:#fff) {
  @if ($index == 2) {
    @include planned($color, $color-unused-3);
  } @else if ($index == 3) {
    @include planned($color, $color-unused-2);
  } @else if ($index == 4) {
    @include planned($color, $color-unused-1);
  }
  background-color: $color;
  text-align: right;

  @if $index == 4 {
    @include pfxSkillWidth();
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  } @else {
    width: 6%;
  }
  z-index: 21 - $index;
  margin-left: 0;
  &:hover {
    filter: brightness(125%);
  }
}
@mixin trileft($color) {
  background-color: $color;
  width: 18px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
  border-right: 1px solid $color-off-dark;
  border-top: 1px solid $color-off-dark;
  border-bottom: 1px solid $color-off-dark;
  z-index: 21;
  margin: 0;
  padding: 2px 8px 8px 6px;
  &:hover {
    filter: none;
  }

  svg {
    margin-top: 4px;
    margin-bottom: -6px;
    margin-left: -1;
    padding-top: 0px;
  }
}
@mixin trione($color) {
  @include planned($color, $color-unused-4);
  background-color: $color;
  z-index: 20;
  &:hover {
    filter: brightness(125%);
  }

  .text {
    margin-left: 8px;
    margin-right: -8px;
    text-overflow: clip;
    overflow-x: visible;
    white-space: nowrap;
  }
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
@mixin tabbed($color) {
  border-left: 4px solid $color;

  .MuiTabs-root {
    .MuiTabs-flexContainer {
      .MuiTabs-scroller {
        .MuiTabs-indicator {
          background-color: $color;
        }
      }
    }
  }
}

.text {


  .resolveLocked {
    font-size: 10px;
    display: inline-flex;
  }
}
.locked {
  margin-bottom: -6px;
  margin-top : -12px;
  margin-right: 8px;
}
.toastWarning {
  background-color: $color-midtone !important;
  opacity: 0.95;

  .toastWarningBody {
    font-family: Alegreya, serif;
    color: $color-light;
  }
}
.cell {
  @include longSkillWidth();
  font-family: 'Bellefair', serif;
  font-size: 14px;
  padding: 8px;
  margin: 4px 0px 0px 0px;
  display: inline-block;
  position: relative;
  color: $color-light;
  cursor: pointer;
  user-select: none;

  &.tier3 {
    height: 12px;
    padding-top: 4px;
    margin-left: -2px;
    &:after {
      border-width: 12px 0 12px 12px !important;
      right: -12px;
    }

    .text {
      margin-right: -4px;

      &.resolveLocked {
        margin-right: -8px;
      }
    }
  }

  &.tier4 {
    margin-left: -12%;
  }

  &.hidden {
    display: none;
  }
}

.combat0 {
  @include trileft($color-combat-4);
}
.combat1 {
  @include trione($color-combat-4);
}
.combat2 {
  @include trixtend($color-combat-3, 2);
}
.combat3 {
  @include trixtend($color-combat-2, 3);
}
.combat4 {
  @include trixtend($color-combat-1, 4, $color-combat-0);
}
.wasteland0 {
  @include trileft($color-wasteland-4);
}
.wasteland1 {
  @include trione($color-wasteland-4);
}
.wasteland2 {
  @include trixtend($color-wasteland-3, 2);
}
.wasteland3 {
  @include trixtend($color-wasteland-2, 3);
}
.wasteland4 {
  @include trixtend($color-wasteland-1, 4, $color-wasteland-0);
}
.anomaly0 {
  @include trileft($color-anomaly-4);
}
.anomaly1 {
  @include trione($color-anomaly-4);
}
.anomaly2 {
  @include trixtend($color-anomaly-3, 2);
}
.anomaly3 {
  @include trixtend($color-anomaly-2, 3);
}
.anomaly4 {
  @include trixtend($color-anomaly-1, 4, $color-anomaly-0);
}
.community0 {
  @include trileft($color-community-4);
}
.community4 {
  background-color: $color-community-4;
  text-align: right;

  &.tier4,
  &.unused4 {
    width: calc(107% - 53px);
    z-index: 16;
    margin-left: calc(-7% + 4px);
    border-radius: 16px;
    &:hover {
      filter: brightness(125%);
    }
  }
}
.civilized0 {
  @include trileft($color-civilized-4);
}
.civilized1 {
  @include trione($color-civilized-4);
}
.civilized2 {
  @include trixtend($color-civilized-3, 2);
}
.civilized3 {
  @include trixtend($color-civilized-2, 3);
}
.civilized4 {
  @include trixtend($color-civilized-1, 4, $color-civilized-0);
}
.unused1 {
  @include trione($color-unused-4);
}
.unused2 {
  @include trixtend($color-unused-3, 2);
}
.unused3 {
  @include trixtend($color-unused-2, 3);
}
.unused4 {
  @include trixtend($color-unused-1, 4, $color-unused-1);
}
.combat1:after {
  @include triafter($color-combat-4);
}
.combat2:after {
  @include triafter($color-combat-3);
}
.combat3:after {
  @include triafter($color-combat-2);
}
.wasteland1:after {
  @include triafter($color-wasteland-4);
}
.wasteland2:after {
  @include triafter($color-wasteland-3);
}
.wasteland3:after {
  @include triafter($color-wasteland-2);
}
.anomaly1:after {
  @include triafter($color-anomaly-4);
}
.anomaly2:after {
  @include triafter($color-anomaly-3);
}
.anomaly3:after {
  @include triafter($color-anomaly-2);
}
.civilized1:after {
  @include triafter($color-civilized-4);
}
.civilized2:after {
  @include triafter($color-civilized-3);
}
.civilized3:after {
  @include triafter($color-civilized-2);
}
.unused1:after {
  @include triafter($color-unused-4);
}
.unused2:after {
  @include triafter($color-unused-3);
}
.unused3:after {
  @include triafter($color-unused-2);
}
.unused0 {
  background-color: $color-unused-4;
  width: 16px;
}

@media screen and (min-width: 480px) {
  .cell {
    &.tier3 {

      .text {
        margin-right: 0px;

        &.resolveLocked {
          margin-right: -4px;
        }
      }
    }
  }
}
