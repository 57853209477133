@import '../../theme.scss';

.container {
	background-color: $color-light;
	color: $color-dark;
	padding: 0px 8px;
	padding-left: 2px;
	border-radius: 4px;
	margin: 0px 4px;
	border-left: 8px solid $color-civilized-4;
}
