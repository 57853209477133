@import '../../theme.scss';

.eventName {
  font-size: 22px;
  white-space: nowrap;
}

.eventLogistics {
  display: inline-flex;
  background-color: $color-light;
  border-radius: 16px;
  color: $color-dark;
  margin: 8px 0;

  .prev {
    padding: 4px;
    cursor: pointer;
    margin-right: -8px;
    border-right: 1px solid $color-off-dark;
  }

  .location {
    padding: 4px 8px 4px 16px;
    border-right: 1px solid $color-off-dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40vw;
  }

  .year {
    padding: 4px 16px;
    border-right: 1px solid $color-off-dark;
  }

  .isPremiere {
    padding: 4px 16px;
    border-right: 1px solid $color-off-dark;
  }

  .time {
    padding: 4px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid $color-off-dark;
  }

  .next {
    padding: 4px;
    cursor: pointer;
  }
}

.icon {
  font-size: 12px;
  margin-top: -4px;
  margin-bottom: -7px;

}

@media print {
  .eventLogistics {
    display: none;
  }
}
