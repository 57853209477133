@import '../../theme.scss';

@mixin base-input {
  color: $color-light;
  font-family: Bellefair, serif;
  margin-top: -6px;
  background-color: $color-dark;
  margin-left: 10px;
  border-radius: 16px;
  padding-left: 8px;
  border: 1px solid $color-off-dark;

  &:hover {
    border: 1px solid $color-light;
  }
}

.container {
  font-family: Bellefair, serif;
  color: $color-light;

  .addCleanupShift {
    text-align: center;
    border-radius: 16px;
    background-color: $color-light;
    color: $color-dark;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  .section {
    margin-bottom: 8px;
    width: 100%;

    .extraBuild {
      margin-bottom: 16px;
      text-align: center;
    }

    .divider {
      border-bottom: 1px solid $color-light;
      margin-top: 12px;

      .header {
        display: inline-flex;
        background-color: $color-light;
        color: $color-dark;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 2px 12px;
        padding-right: 32px;
      }
    }

    .description {
      margin-bottom: 12px;
      padding: 4px;
    }

    .day {
      text-transform: capitalize;
      font-size: 24px;
      border-bottom: 1px solid $color-midtone;
      margin-bottom: 8px;
    }

    .row {
      margin-bottom: 8px;
      display: flex;
      width: calc(100% - 0px);

      &.disabled {
        .name {
          .inputText {
            color: $color-midtone;
          }
        }

        .limit {
          .inputText {
            color: $color-midtone;
          }
        }
      }

      .price {
        flex: 0 0 10%;
        max-width: 10%;

        .inputText {
          @include base-input;
          padding-left: 8px;
          width: calc(100% + 8px);
          margin-left: 0;
          padding-right: 8px;
          background-color: $color-dark;
          color: $color-light;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;

          :global(.MuiInputBase-input) {
            text-align: right;
          }

          &:before {
            content: '$';
            margin-top: -2px;
          }
        }
      }

      .toggle {
        flex: 0 0 10%;
        max-width: 10%;
      }

      .name {
        flex: 0 0 75%;
        max-width: 75%;

        .inputText {
          @include base-input;
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.ticket {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;

          .forInput {
            flex: 0 0 100%;
            z-index: 1;

            .inputText {
              border-radius: 0;
            }
          }

          .forShift {
            z-index: 2;
            justify-items: flex-end;
            margin-top: -27px;
            color: $color-midtone;
          }
        }
      }

      .remove {
        z-index: 20;
        background-color: $color-midtone;
        color: $color-light;
        border-radius: 16px;
        padding: 0px 8px;
        margin-left: -56px;
        margin-right: -8px;
        cursor: pointer;
        height: 22px;

        .text {
          margin-top: 1px;
        }

        &:hover {
          background-color: $color-light;
          color: $color-dark;
        }

        &.hidden {
          display: none;
        }
      }

      .limit {
        flex: 0 0 15%;
        max-width: 15%;


        .inputText {
          @include base-input;
          margin-left: 12px;
          padding-right: 16px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;

          :global(.MuiInputBase-input) {
            text-align: right;
          }
        }
      }
    }
  }
}
