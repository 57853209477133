@import '../../theme.scss';

.eventLinks {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-family: Bellefair, serif;
  font-size: 16px;

  .link {
    color: $color-light;
    //padding: 4px 8px;
    background-color: $color-dark;
    color: $color-light;
    text-decoration: none;
    border-right: 1px solid $color-midtone;
    flex: 0 1 15%;
    text-align: center;
    align-self: center;
    padding: 1px;

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }

    &:first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      padding: 1px;
    }

    &:nth-last-child(4) {
      padding: 4px 8px;
    }

    &:nth-last-child(3) {
      padding: 4px 8px;
    }

    &:nth-last-child(2) {
      padding: 4px 8px;
    }

    &:last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border-right: 0;
      padding: 1px;
    }

    &.current {
      background-color: $color-light;
      color: $color-dark;

      &:hover {
      }
    }
  }
}

.tooltip {
  font-size: 16px;
  font-family: Bellefair, serif;
  background-color: $color-light !important;
  color: $color-dark !important;

  &:after {
    border-bottom-color: $color-light !important;
  }

  &:global(.place-left) {
    &:after {
      border-bottom-color: $color-dark !important;
      border-left-color: $color-light !important;
      border-right-color: $color-light !important;
    }
  }

  &:global(.place-right) {
    &:after {
      border-bottom-color: $color-dark !important;
      border-left-color: $color-light !important;
      border-right-color: $color-light !important;
    }
  }
}

@media print and (max-width: 480px) {
  .eventLinks {
    .link {
      &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(2) {
        padding: 4px 8px;
      }
    }
  }
}
@media print {
  .eventLinks {
    display: none;
  }
}
