@import '../theme.scss';

.container {
  max-width: 640px;
  width: calc(100vw - 256px);
  min-width: 464px;
  margin: 0 auto;
  margin-bottom: 32px;

  .content {
    .yearContainer {
      margin-bottom: 16px;

      .yearHeader {
        font-size: 24px;
        border-bottom: 1px solid $color-light;
        padding-bottom: 4px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
        }

        .numeric {
          font-size: 16px;
        }
      }

      .yearContent {
        .monthContainer {
          margin-bottom: 8px;

          .monthHeader {
            font-size: 16px;
            border-bottom: 1px solid $color-midtone;
            padding-bottom: 4px;
            padding-left: 16px;
            margin-bottom: 8px;
            display: flex;

            .monthName {
              flex: 1 1 160px;
            }

            .playerName {
              flex: 1 1 128px;
              text-align: left;
            }

            .dateExecuted {
              flex: 1 0 32px;
              text-align: right;
            }

            .dateActivated {
              flex: 1 0 72px;
              text-align: right;
            }
          }

          .monthContent {
            .dataRow {
              display: flex;
              margin-bottom: 4px;

              .grantorID {
                flex: 1 1 32px;
                text-align: right;
              }

              .grantorName {
                flex: 1 1 128px;
                margin-left: 8px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
              }

              .userID {
                flex: 1 1 32px;
                text-align: right;
                margin-left: 8px;
              }

              .userName {
                flex: 1 1 128px;
                margin-left: 8px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
              }

              .dateExecuted {
                flex: 1 1 32px;
                text-align: right;
                margin-left: 8px;
              }

              .dateActivated {
                flex: 1 1 64px;
                text-align: right;
                margin-left: 8px;
              }
            }
          }

          .monthlyFooter {
            border-top: 1px solid $color-midtone;
            border-bottom: 1px solid $color-midtone;
            padding: 4px 0px 4px 16px;
            text-align: ri;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {

            }

            .numeric {
              text-align: right;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100vw;
    min-width: unset;
    max-width: calc(100vw - 16px);
  }
}
