@import '../../../theme.scss';

@mixin innerStyle() {
  height: 32px;
  border-radius: 32px;
  background-color: $color-light;
  color: $color-dark;
  border: 1px solid $color-off-dark;
}

@mixin commonArrow() {
  border-style: solid;
  border-width: 16px 0px 16px 16px;
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 4;
}

.container {
  display: flex;
  font-family: Alegreya, serif;
  font-size: 14px;
  margin-top: 4px;
  justify-content: flex-start;
  cursor: pointer;
  text-align: center;
  flex-wrap: wrap;
  user-select: none;

  &:focus {
    outline: none !important;
  }

  .controls {
    @include innerStyle();
    width: 32px;
    z-index: 4;
    cursor: pointer;

    .text {
      font-size: 24px;
      margin-top: -2px;
    }

    &.up {
      margin-left: -32px;
    }

    &.disabled {
      background-color: $color-off-dark;
      border-color: $color-midtone;
      color: $color-midtone;
    }
  }

  .educationLevel {
    @include innerStyle();
    width: 112px;
    margin-left: -24px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text {
      margin-left: -8px;
      padding: 0px 24px;
      line-height: 1;
    }
  }

  .loreMarker {
    @include innerStyle();
    width: 16px;
    background-color: $color-dark;
    border-radius: 0;
    border: 1px solid $color-dark;
    margin-left: 0px;
    margin-right: 0px;
    content: '';

    &:before {
      @include commonArrow();
      border-color: transparent transparent transparent $color-dark;
      margin-top: 0px;
      margin-left: -8px;
      z-index: 3;
    }

    &:after {
      @include commonArrow();
      border-color: transparent transparent transparent $color-dark;
      margin-top: 0px;
      margin-left: 7.5px;
      z-index: 4;
    }

    &.available {
      background-color: $color-midtone;
      border-color: $color-off-dark;

      &:before {
        border-left-color: $color-off-dark;
      }

      &:after {
        border-left-color: $color-midtone; 
      }
    }

    &.used {
      background-color: $color-light;
      border-color: $color-off-dark;

      &:before {
        border-left-color: $color-off-dark;
      }

      &:after {
        border-left-color: $color-light;
      }
    }

    &.error {
      background-color: $color-error-light;

      &:after {
        border-left-color: $color-error-light;
      }
    }
  }

  .numeric {
    @include innerStyle();
    // education = 112px
    // 9x grid = px
    // 2x controls = 64px
    // add 8px for overlaps (not subtract!)
    width: calc(100% - 112px - 108px - 64px);
    border-radius: 0px;
    margin-left: -24px;
    
    .text {
      margin-top: 6px;
      margin-right: -6px;
      margin-left: 18px;

      .optional {
        display: none;
      }
    }

    &:after {
      @include commonArrow();
      border-color: transparent transparent transparent $color-light;
      margin-top: -25px;
      margin-left: calc((100% - 112px - 108px - 64px) / 2);
      z-index: 4;
    }

    &.error {
      background-color: $color-error-light;
      color: $color-error;

      &:after {
        border-left-color: $color-error-light;
      }
    }
  }
}

// asked ouija board how to get these numbers
@media screen and (min-width: 497px) {
  .container {
    .numeric {
      &:after {
        margin-left: 98px;
      }
    }
  }
}

@media screen and (min-width: 384px) {
  .container {
    .numeric {
      .text {
        margin-left: 18px;
        .optional {
          display: inline;
        }
      }
    }
  }
}
