@import '../theme.scss';

@media print {
  .superContainer {
    position: relative;
    width: auto;
    height: auto;
    overflow-x: hidden !important;
  }

  .container {
    padding-top: 0 !important;
    min-width: unset;
    width: auto;
  }
}

.superContainer {
  position: fixed;
  width: 100vw;
  overflow-x: scroll;
  height: calc(100vh - 56px);
}

.container {
  min-width: 640px;
  max-width: 1200px;
  background-color: white;
  color: $color-dark;
  font-family: Alegreya, serif;
  margin: 0 auto;
  margin-top: 48px;
  padding: 32px 32px 64px 32px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;

  .administration {
    display: flex;
    justify-content: space-between;

    .character {
      .name {
        font-size: 24px;
        max-width: 50vw;
      }
    }

    .player {
      text-align: right;
      max-width: 50vw;
      .number {
      }
      .name {
        font-size: 24px;
      }
    }
  }

  .xp {
    display: flex;
    justify-content: space-between;

    .stat {
      width: 50%;
      margin-right: 16px;
    }

    .skill {
      width: 50%;
      margin-left: 16px;
    }
  }

  .build {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    .skills {
      width: 50%;
      margin-right: 16px;

      .table {
        margin-top: 16px;
      }
    }

    .lores {
      width: 50%;
      margin-left: 16px;

      .table {
        margin-top: 16px;
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.numeric {
  text-align: right;
}

.header {
  font-weight: bold;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
