@import '../../theme.scss';

.wrapper {
  display: flex;
  margin-top: 8px;
  width: 100%;

  .title {
    width: 40%;
    background-color: $color-light;
    color: $color-dark;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 6px 4px 6px 16px;
    font-size: 16px;

    &.error {
      background-color: $color-error-light;
      color: $color-error;
    }
  }
}

.dropdown {
  width: calc(60% - 16px);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid $color-midtone;
  padding: 0px 16px 0px 8px;
  margin-right: 6px;

  &:hover {
    filter: brightness(125%);
  }

  .select {
    font-family: Bellefair, serif;
    font-size: 16px;
    
    color: $color-light;
    margin-top: -2px;


  }

  .icon {
    color: $color-light;

    
  }
}

.menu {
  :global(.MuiMenu-paper) {
    background-color: $color-dark;
    max-height: 50vh;
    margin-left: 0px;
    border-radius: 16px;
    color: $color-light;

    .item {
      font-family: Alegreya, serif;
      padding: 8px 16px;
      min-height: 0px;

      &.disabled {
        color: $color-light;
      }

      &:global(.Mui-selected) {
        background-color: $color-light;
        color: $color-dark;
      }
    }
  }  
}

.undo {
  cursor: pointer;
  margin-right: 7px;
  margin-top: 2px;

  &:hover {
    filter: brightness(125%);
  }

  &.hidden {
    cursor: default;
    visibility: hidden;
  }

  &.spaceless {
    display: none;
  }
}
