@import '../../theme.scss';

.container {
  width: calc(100vw - 248px);
  margin: 48px auto 0px auto;

  .wrapper {
    max-width: 640px;
    min-width: 464px;
    width: calc(100vw - 264px);
    margin: 0 auto;
    margin-top: 8px;

    .buffer {
      height: 8px;
    }

    .modBlocked {
      text-align: center;
      margin: 32px 0px;

      .icon {
        font-size: 80px;
      }

      .text {
        margin-top: 32px;
        font-size: 24px;
      }
    }

    .modManagerHeader {
      display: flex;
      align-items: flex-end;
      margin-bottom: 8px;
      border-bottom: 1px solid $color-light;

      .title {
        flex: 1 1 50%;
        font-size: 24px;
      }

      .name {
        flex: 1 1 50%;
        text-align: right;
        padding-bottom: 4px;
      }
    }

    .dateControls {
      display: flex;
      font-family: Bellefair, serif;
      color: $color-light;

      .dateHalf {
        flex: 0 0 50%;

        .divider {
          height: 24px;
        }

        .premiereToggle {
          display: flex;
          justify-content: center;
          margin: 16px 0;

          .text {
            margin-top: 2px;
            margin-left: 12px;
          }
        }
      }
    }

    .tabs {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      border-bottom: 1px solid $color-light;
      margin-bottom: 8px;

      .tab {
        padding: 8px 8px;
        text-align: center;
        border: 1px solid $color-midtone;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 0;
        margin: 0px 4px;
        width: 100%;
        cursor: pointer;

        &.selected {
          background-color: $color-light;
          color: $color-dark;
          cursor: default;

          &:hover {
            filter: none;
          }
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          filter: brightness(125%);
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .container {
    width: 100vw;
    padding: 0;

    .wrapper {
      width: calc(100vw - 8px);
      min-width: 0;
    }
  }
}
