@import '../theme.scss';

.unimpersonate {
  font-family: Bellefair, sans-serif;
  background-color: $color-off-dark;
  padding: 8px 0px 8px 16px;
  border-top: 1px solid $color-midtone;
  color: $color-light;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: $color-light;
    color: $color-dark;
  }

  .text {
    margin-top: 1px;
    margin-left: 4px;
  }
}
