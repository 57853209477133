@import '../../theme.scss';

.container {
	display: flex;
	justify-content: space-between;

	.section {
		background-color: $color-dark;
		color: $color-light;
		text-align: center;
		flex: 1 1 20%;
		padding: 4px 0px;
		border-right: 1px solid $color-midtone;

		&:hover {
			background-color: $color-light;
			color: $color-dark;
			cursor: pointer;
			transition: all 0.25s ease-in-out;
		}

		&:first-of-type {
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
		}

		&:last-of-type {
			border-top-right-radius: 16px;
			border-bottom-right-radius: 16px;
			border-right: 0;
		}

		&.selected {
			background-color: $color-light;
			color: $color-dark;

			&:hover {
				cursor: default;
			}
		}
	}
}
