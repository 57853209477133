@import '../../theme.scss';

.lore {
  background-color: $color-off-dark;
  color: $color-midtone;
  border-radius: 32px;
  border: 1px solid $color-midtone;
  padding: 8px 8px 8px 16px;
  font-family: Bellefair, serif;
  font-size: 14px;
  margin: 2px 0px 4px 0px;
  cursor: pointer;
  word-break: break-word;
  word-wrap: break-word;
  user-select: none; 

  &.planned {
    background: linear-gradient(
      135deg,
      $color-off-dark 49%,
      $color-midtone 50%,
    );
    color: $color-light;
  }

  &.acquired {
    background-color: $color-light;
    color: $color-dark;
  }
}