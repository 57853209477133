@import '../../theme.scss';

.container {
	margin: 8px 0px;

	&.compact {
		margin: 0px 8px;

		&.left {
			.input {
				text-align: left;
			}

			.overlay {
				justify-content: flex-end;
				flex-direction: row-reverse;

				.text {
					text-align: left;
					margin-left: 8px;
					margin-right: 0;
				}
			}
		}

		.input {
			padding: 0px 8px;
			background-color: $color-dark;
			border-bottom: 1px solid $color-midtone;
			text-align: right;
			border-radius: 0;
		}

		.overlay {
			background-color: $color-off-dark;
			border-radius: 0;
			color: $color-light;
			padding: 0px 8px;
			margin-top: -19px;
			justify-content: flex-end;

			.text {
				text-align: right;
				margin-right: 8px;
			}
		}

		.options {
			left: unset;
		}
	}

	.input {
		font-family: Bellefair, serif;
		font-size: 16px;
		color: $color-light;
		background-color: $color-dark;
		border: 0;
		padding: 8px 16px;
		border-radius: 16px;
		width: 192px;

		&.error {
			background-color: $color-error-light;
			color: $color-error;
		}
	}

	.overlay {
		position: absolute;
		margin-top: -32px;
		width: 192px;
		padding: 8px 16px;
		border-radius: 16px;
		background-color: $color-light;
		color: $color-dark;
		display: flex;
		justify-content: space-between;

		.text {
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}

		.close {
			cursor: pointer;
		}
	}

	.options {
		position: absolute;
		margin-top: 8px;
		box-shadow: 0px 4px 20px 0px $color-dark;
		z-index: 1001;

		.info {
			padding: 8px 16px;
			background-color: $color-off-dark;
		}

		.title {
			background-color: $color-light;
			color: $color-dark;
			padding: 8px 16px;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
		}

		.content {
			padding: 8px 0px;
			background-color: $color-off-dark;

			.character {
				padding: 8px 16px;
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
				width: 192px;

				&:hover {
					background-color: $color-light;
					color: $color-dark;

					.status {
						.text {
							background-color: $color-off-dark;
							color: $color-light;
						}
					}
				}

				.checkedIn {
					flex: 0 0 24px;

					.icon {
						font-size: 16px;
					}
				}

				.name {
					white-space: nowrap;
					overflow-x: hidden;
					text-overflow: ellipsis;
				}

				.status {
					margin-left: 16px;

					.text {
						background-color: $color-light;
						color: $color-dark;
						border-radius: 4px;
						padding: 0px 4px;
					}
				}
			}
		}
	}
}
