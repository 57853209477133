@import '../../theme.scss';

.container {
  margin-top: 8px;

  .header {
    border-bottom: 1px solid $color-light;
    font-size: 22px;
    text-align: center;
    margin-top: 16px;
  }

  .shiftList {
    max-height: 33vh;
    overflow-y: auto;

    .row {
      display: flex;
      justify-content: flex-start;
      margin-top: 4px;

      &.disabled {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .toggle {
        flex: 0 0 15%;
        justify-content: center;
        text-align: center;
      }

      .day {
        flex: 0 0 20%;
        text-align: right;
        padding-right: 16px;
      }

      .label {
        flex: 0 0 50%;

        &.disabled {
          text-decoration: line-through;
          color: $color-midtone;

          &:after {
            content: ' (Full)'
          }
        }
      }
    }
  }
}
