@import '../../../../theme.scss';
@import '../quadTone.scss';

.skillgrid-overlay {
  @include quadTone();
  background-color: $color-light;
  width: 75%;
  max-width: 224px;
  padding: 8px 16px 6px 12px;
  border: 0;
  margin: 18.5px 0 0 0;
  border-radius: 16px;
  font-family: 'Bellefair', serif;
  font-size: 14px;
  z-index: 24;
  user-select: none;

  &.muted {
    background-color: $color-light;
    color: $color-dark;

    .table-container {
      table.summary-table {
        tbody {
          tr {
            td.t4 {
              border-left-color: $color-dark;
            }
          }
        }
      }
    }
  }
  &.combat {
    border-bottom-left-radius: 0px;
  }
  &.anomaly {
    border-bottom-right-radius: 0px;
  }

  .table-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    table.summary-table {
      width: 85%;

      tbody {
        tr {
          td.numeric {
            text-align: right;
            width: 24px;
          }

          td.t4 {
            border-left: 1px dashed $color-light;
          } 
        }
      }
    }

    .bold {
      font-weight: bolder;
      text-decoration: underline;
    }

    .MuiSvgIcon-root {
      cursor: pointer;
    }
    .MuiSvgIcon-root.hidden {
      display: none;
    }
  }
}
